import { useEffect, useState } from "react";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { Fade } from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateRecipient } from "./redux/Recipient";
import axios from "axios";
import ReactSelect from "react-select";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { setSidebarMode, setSidebarShow } from "./redux/Sidebar";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const Scroll = () => {
  useLockBodyScroll();
  return <></>;
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const totalAmountToPay = useSelector(
    (state) => state.sidebar.totalAmountToPay
  );
  const cartItems = useSelector((state) => state.cart.items);
  const recipient = useSelector((state) => state.recipient.recipient);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const res = await axios.post(
      process.env.REACT_APP_PROXY_SERVER + "create-intent",
      {
        amount: Math.round(Number(totalAmountToPay * 100)),
        recipient: recipient,
        items: Object.values(cartItems),
      }
    );

    const clientSecret = res.data.clientSecret;
    if (!clientSecret) {
      toast.error("Something went wrong..");
      return;
    }

    const { error } = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: "https://biorbank.com/merch/track",
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      toast.success("Payment transfered successfully!");
      // handlePurchase();
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || !elements}
        className="w-full mt-3 text-center px-[14px] py-[10px] rounded-[8px] bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
      >
        Pay
      </button>
      {/* Show error message to your customers */}
      {errorMessage && (
        <div className="mt-3 text-[#f70892]">{errorMessage}</div>
      )}
    </form>
  );
};

export const SideBar = ({ show, onHide = () => {} }) => {
  const [width, setWidth] = useState(0);
  const [isVisible, setIsVisible] = useState();
  const navigate = useNavigate();
  const [recipient, setRecipient] = useState({
    name: "",
    // company: "",
    address1: "",
    address2: "",
    city: "",
    state_code: "",
    state_name: "",
    country_code: "US",
    country_name: "United States",
    zip: "",
    phone: "",
    email: "",
    tax_number: "",
    // name: "Hassen Kadhim",
    // address1: "1007 - 2200 ward",
    // address2: "",
    // city: "Montreal",
    // state_code: "",
    // state_name: "Quebec",
    // country_code: "CA",
    // country_name: "Canada",
    // zip: "H4M2R1",
    // phone: "+14385216292",
    // email: "bullware@biorbank.com",
    // tax_number: "",
  });
  const cartItems = useSelector((state) => state.cart.items);
  const purchasable = useSelector((state) => state.sidebar.purchasable);
  const totalAmountToPay = useSelector(
    (state) => state.sidebar.totalAmountToPay
  );
  const [countries, setCountries] = useState([]);
  const getAvailableCountries = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_PROXY_SERVER + "countries")
    ).data;

    let countries = {};
    for (let i = 0; i < res.length; i++) {
      countries[res[i].name] = res[i];
    }
    setCountries(countries);
  };
  // const [{ isPending }] = usePayPalScriptReducer();

  const handleCancelOrder = async (orderId) => {
    const res = (
      await axios.post(process.env.REACT_APP_PROXY_SERVER + "cancelOrder", {
        orderId: orderId.toString(),
      })
    ).data;
    console.log(res);
  };

  useEffect(() => {
    getAvailableCountries();
  }, []);

  useEffect(() => {
    if (isVisible) {
      if (show === false) {
        setTimeout(() => {
          setIsVisible(false);
        }, 150);
        setWidth(0);
      }
    } else {
      if (show) {
        setIsVisible(true);
      }
    }
  }, [show, isVisible]);

  useEffect(() => {
    if (isVisible) {
      if (mode !== null) setWidth(100);
      else setWidth(60);
    }
  }, [isVisible]);

  const mode = useSelector((state) => state.sidebar.mode);
  const dispatch = useDispatch();
  return (
    <div
      className="w-[100vw] h-[100vh] bg-[#c0c0c0c0] sm:hidden block z-[200] fixed top-0"
      onClick={() => {
        onHide();
      }}
      style={{
        fontFamily: "Trueno",
        display: isVisible ? "inherit" : "none",
      }}
      // hidden={!isVisible}
    >
      <div
        className={
          // width === 100
          // ? `overflow-auto w-[${width}vw] sm:max-w-[330px] h-[calc(100vh-330px)] bg-white fixed top-[340px] right-0 transition-all shadow-lg p-[20px] animation-funding`
          // :
          `overflow-auto w-[${width}vw] sm:max-w-[330px] h-[100vh] bg-white fixed top-0 right-0 transition-all shadow-lg p-[20px] animation-funding`
        }
        onClick={(e) => e.stopPropagation()}
      >
        {mode === null && (
          <div className="space-y-[10px] text-[#070623] mt-[30px] text-[14px]">
            <div>
              <a href="mailto:ContactUs@biorbank.com">
                <div className="cursor-pointer hover:text-[#a0a0a0]">
                  Contact Us
                </div>
              </a>
            </div>
            <hr />
            <div
              className="cursor-pointer hover:text-[#a0a0a0]"
              onClick={() => {
                document
                  .getElementById("aboutUs")
                  .scrollIntoView({ behavior: "smooth" });
              }}
            >
              About Us
            </div>
            <hr />
            {/* <div>
              <a
                href="https://drive.google.com/file/d/1lKvfGSW-d-enogLV7XWoVsQ2BndjMcep/view?usp=sharing"
                target="blank"
              >
                <div className="cursor-pointer hover:text-[#a0a0a0]">
                  Litepaper
                </div>
              </a>
            </div>
            <hr /> */}
            <div
              className="cursor-pointer hover:text-[#a0a0a0]"
              onClick={() => {
                navigate("/merch/track");
                dispatch(setSidebarShow(false));
              }}
            >
              Track Order
            </div>
            <hr />
            <div>
              <Fade delay={150}>
                {show && (
                  <div
                    data-tooltip-id="tooltip"
                    className="px-[14px] py-[10px] w-full text-center rounded-full bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
                    // style={{ fontFamily: "Trueno Light" }}
                    onClick={() => {
                      navigate("/merch");
                      dispatch(setSidebarShow(false));
                    }}
                  >
                    Buy Merch
                  </div>
                )}
              </Fade>
            </div>
          </div>
        )}
        {mode === false && (
          <>
            <div className="flex items-center justify-between">
              <div className="">Add Address</div>
              <svg
                className="cursor-pointer"
                onClick={() => onHide()}
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.28631 10.714L10.7144 1.28595M1.28631 1.28595L10.7144 10.714"
                  stroke="#55606D"
                  stroke-width="2.5"
                  stroke-linecap="round"
                />
              </svg>
            </div>
            <div className="space-y-[10px] mt-[30px] text-[14px]">
              <div>Insert name*</div>
              <input
                placeholder="John Smith"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.name}
                onChange={(e) =>
                  setRecipient({ ...recipient, name: e.target.value })
                }
              />
              <div>Address 1*</div>
              <input
                placeholder="19740 Dearhorn St"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.address1}
                onChange={(e) =>
                  setRecipient({ ...recipient, address1: e.target.value })
                }
              />
              <div className="flex gap-[6px]">
                <div>Address 2 </div>
                <div className="text-[#a5a5a5]">(optional)</div>
              </div>
              <input
                placeholder="Address"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.address2}
                onChange={(e) =>
                  setRecipient({ ...recipient, address2: e.target.value })
                }
              />
              <div>City*</div>
              <input
                placeholder="Chatsworth"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.city}
                onChange={(e) =>
                  setRecipient({ ...recipient, city: e.target.value })
                }
              />
              <div>Country*</div>
              {/* <input
                placeholder="United States"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.country_name}
                onChange={(e) =>
                  setRecipient({ ...recipient, country_name: e.target.value })
                }
              /> */}

              <ReactSelect
                placeholder="Country"
                styles={{
                  control: () => ({
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#F6F8FC",
                    borderRadius: "8px",
                  }),
                  indicatorSeparator: () => ({
                    display: "none",
                  }),
                  option: (provided) => ({
                    ...provided,
                    color: "black",
                    ":hover": {
                      backgroundColor: "#ddd",
                    },
                    backgroundColor: "white",
                  }),
                }}
                options={Object.values(countries)
                  .slice()
                  .map((item) => {
                    return {
                      label: item.name,
                      value: item.code,
                    };
                  })}
                onChange={(option) =>
                  setRecipient({
                    ...recipient,
                    country_name: option.label,
                    country_code: option.value,
                  })
                }
                value={{
                  label: recipient.country_name,
                  value: recipient.country_code,
                }}
              />
              <div className="grid grid-cols-2 gap-[10px]">
                <div className="space-y-[10px]">
                  <div>Province/Territory*</div>

                  {countries[recipient.country_name]?.states ? (
                    <ReactSelect
                      placeholder="State"
                      value={{
                        label: recipient.state_name,
                        value: recipient.state_code,
                      }}
                      styles={{
                        control: () => ({
                          width: "100%",
                          display: "flex",
                          backgroundColor: "#F6F8FC",
                          borderRadius: "8px",
                        }),
                        indicatorSeparator: () => ({
                          display: "none",
                        }),
                        option: (provided) => ({
                          ...provided,
                          color: "black",
                          ":hover": {
                            backgroundColor: "#ddd",
                          },
                          backgroundColor: "white",
                        }),
                      }}
                      options={countries[recipient.country_name]?.states
                        ?.slice()
                        .map((item) => {
                          return {
                            label: item.name,
                            value: item.code,
                          };
                        })}
                      onChange={(option) =>
                        setRecipient({
                          ...recipient,
                          state_name: option.label,
                          state_code: option.value,
                        })
                      }
                    />
                  ) : (
                    <input
                      placeholder="California"
                      className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                      value={recipient.state_name}
                      onChange={(e) =>
                        setRecipient({
                          ...recipient,
                          state_name: e.target.value,
                        })
                      }
                    />
                  )}
                </div>
                <div className="space-y-[10px]">
                  <div>Zip/Postal Code*</div>
                  <input
                    placeholder="H5L2T5"
                    className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                    value={recipient.zip}
                    onChange={(e) =>
                      setRecipient({ ...recipient, zip: e.target.value })
                    }
                  />
                </div>
              </div>
              <div>Email*</div>
              <input
                placeholder="Johnsmith@gmail.com"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.email}
                onChange={(e) =>
                  setRecipient({ ...recipient, email: e.target.value })
                }
              />
              <div>Phone Number*</div>
              <input
                placeholder="number"
                className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
                value={recipient.phone}
                onChange={(e) =>
                  setRecipient({ ...recipient, phone: e.target.value })
                }
              />
              <div className="text-[#a5a5a5]">
                We'll contact you in case anything comes up with your order
              </div>
            </div>

            <div className="grid grid-cols-2 items-center gap-[20px] mt-[20px] sm:mb-0 mb-[100px]">
              <div
                onClick={() => {
                  onHide();
                }}
                className="text-center px-[14px] py-[10px] rounded-[8px] bg-white text-[#1C1460] border-[1px] border-[#1C1460] cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
              >
                Cancel
              </div>
              <div
                onClick={() => {
                  dispatch(updateRecipient(recipient));
                  dispatch(setSidebarMode(true));
                  // handleCancelOrder("108270515");
                }}
                className="text-center px-[14px] py-[10px] rounded-[8px] bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
              >
                Confirm
              </div>
            </div>
          </>
        )}
        {mode === true && (
          <>
            {purchasable && totalAmountToPay > 0 ? (
              <>
                <div className="flex items-center justify-between mb-5">
                  <div className="">Payment</div>
                  <svg
                    className="cursor-pointer"
                    onClick={() => onHide()}
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.28631 10.714L10.7144 1.28595M1.28631 1.28595L10.7144 10.714"
                      stroke="#55606D"
                      stroke-width="2.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
                <div className="items-center flex justify-between text-[14px] mb-3">
                  <div>Estimated Total</div>
                  <div>${totalAmountToPay} (CAD)</div>
                </div>

                <>
                  {/* <PayPalButtons
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: totalAmountToPay.toString(),
                                currency_code: "CAD",
                              },
                              payee: {
                                email_address: "bullware@biorbank.com",
                              },
                              // shipping: {
                              //   address: {
                              //     address_line_1: recipient.address1,
                              //     admin_area_1: recipient.state_code,
                              //     admin_area_2: recipient.city,
                              //     postal_code: recipient.zip,
                              //     country_code: recipient.country_code,
                              //   },
                              //   name: {},
                              // },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture({}).then((details) => {
                          console.log(details);
                          handlePurchase();
                        });
                      }}
                      onCancel={() => {
                        // handleCancelOrder()
                      }}
                      onError={() => {}}
                    /> */}

                  <CheckoutForm />
                </>
              </>
            ) : (
              <>
                <div className="flex items-start justify-between">
                  {purchasable === null ? (
                    <ClipLoader />
                  ) : (
                    <div className="w-[80%]">
                      Please check your address and Cart items again
                    </div>
                  )}

                  <svg
                    className="cursor-pointer"
                    onClick={() => onHide()}
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.28631 10.714L10.7144 1.28595M1.28631 1.28595L10.7144 10.714"
                      stroke="#55606D"
                      stroke-width="2.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </>
            )}
          </>
        )}
      </div>
      {isVisible && <Scroll />}
    </div>
  );
};
