import "./App.css";

import { useEffect, useState } from "react";
import FontFaceObserver from "fontfaceobserver";
import { Home } from "./Pages/Home";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { MerchLayout } from "./Pages/Merch";
import { Header } from "./Pages/Header";
import { Store } from "./Pages/Components/Store";
import { Cart } from "./Pages/Components/Cart";
import { SideBar } from "./Modal";
import { useSelector, useDispatch } from "react-redux";
import { setSidebarShow } from "./redux/Sidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TrackOrder } from "./Pages/Components/TrackOrder";
import "react-loading-skeleton/dist/skeleton.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

function App() {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.sidebar.show);
  // useEffect(() => {
  //   try {
  //     var font = new FontFaceObserver("Trueno");

  //     font.load().then(() => {
  //       setVisible(true);
  //     });
  //   } catch (error) {}
  // }, []);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPEKEY);
  const totalAmountToPay = useSelector(
    (state) => state.sidebar.totalAmountToPay
  );

  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: "payment",
        amount: Math.round(Number((totalAmountToPay || 1) * 100)),
        currency: "cad",
      }}
    >
      <div
        style={{
          fontFamily: "Trueno",
          overflowX: "hidden",
        }}
        className="relative w-[100vw] h-screen"
        // hidden={!visible}
      >
        <Router>
          <Routes>
            <Route path="*" element={<Header />}>
              <Route path="" element={<Home />}></Route>
              <Route path="merch/*" element={<MerchLayout />}>
                <Route path="" element={<Store />}></Route>
                <Route path="cart" element={<Cart />}></Route>
                <Route path="track" element={<TrackOrder />}></Route>
              </Route>
            </Route>
          </Routes>
          <SideBar show={menu} onHide={() => dispatch(setSidebarShow(false))} />
        </Router>

        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          theme="light"
        />
      </div>
    </Elements>
  );
}

export default App;
