import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  recipient: {},
};

export const RecipientSlice = createSlice({
  name: "recipient",
  initialState,
  reducers: {
    updateRecipient: (state, action) => {
      state.recipient = action.payload;
    },
  },
});

export const { updateRecipient } = RecipientSlice.actions;

export default RecipientSlice.reducer;
