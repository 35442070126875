import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: {},
};

export const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action) => {
      if (state.items[action.payload.external_id]) {
        if (
          state.items[action.payload.external_id].quantity +
            action.payload.quantity <=
          0
        )
          delete state.items[action.payload.external_id];
        else
          state.items[action.payload.external_id].quantity +=
            action.payload.quantity;
      } else state.items[action.payload.external_id] = action.payload;
    },
  },
});

export const { addToCart } = CartSlice.actions;

export default CartSlice.reducer;
