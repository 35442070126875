import { configureStore } from "@reduxjs/toolkit";

import CartReducer from "../redux/Cart";
import RecipientReducer from "../redux/Recipient";
import SideBarReducer from "../redux/Sidebar";

export const Store = configureStore({
  reducer: {
    cart: CartReducer,
    recipient: RecipientReducer,
    sidebar: SideBarReducer,
  },
});
