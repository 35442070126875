import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useEffect, useState } from "react";
import { Merches } from "../../Merch/Products";
import { setOrderId } from "../../redux/Sidebar";

export const TrackOrder = () => {
  const dispatch = useDispatch();
  const orderId = useSelector((state) => state.sidebar.orderId);
  const [orderData, setOrderData] = useState();
  const handleGetOrder = async (orderId) => {
    const res = (
      await axios.post(process.env.REACT_APP_PROXY_SERVER + "getOrder", {
        orderId: orderId.toString(),
      })
    ).data;
    setOrderData(res.result);
    if (!res.result) dispatch(setOrderId(null));
  };

  useEffect(() => {
    if (orderId) handleGetOrder(orderId);
  }, [orderId]);

  const [inputValue, setInputValue] = useState(orderId);

  return (
    <div className="mx-auto sm:w-[500px] z-[100] w-[96vw] p-[20px] bg-white border-[1px] rounded-[8px] my-6 space-y-[10px]">
      <div className="w-full flex gap-[20px] justify-between items-center">
        <input
          placeholder="Your Order ID"
          className="rounded-[8px] w-full p-[8px] outline-none bg-[#F6F8FC]"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />

        <div
          onClick={() => dispatch(setOrderId(inputValue))}
          className="text-center px-[30px] py-[8px] rounded-[8px] bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
        >
          Track
        </div>
      </div>
      {orderData ? (
        <div className="w-full flex justify-between items-center">
          <div>Order</div>
          <div>{orderId}</div>
        </div>
      ) : (
        <>
          {orderId && (
            <div className="text-[#ef0000]">Cannot track such order</div>
          )}
        </>
      )}

      {orderData && (
        <>
          <div className="w-full flex justify-between items-center">
            <div>Status</div>
            <div
              className={
                orderData.status === "canceled" ? "text-[#fa0000]" : ""
              }
            >
              {orderData.status}
            </div>
          </div>
          <div className="w-full flex justify-between items-start">
            <div>Shipping</div>
            <div className="text-[#aaaaaa] w-[30%]">
              {orderData.shipping_service_name}
            </div>
          </div>
          {/* <div className="w-full flex justify-between items-start">
            <div>Recipient</div>
            <div className="sm:w-[300px] w-[200px] truncate">
              {Object.entries(orderData.recipient).map((item, i) => {
                if (i < 5)
                  return (
                    <div className="w-full flex justify-between items-center">
                      <div className="text-[#888888] truncate">{item[0]}</div>
                      <div className="truncate text-[#aaaaaa]">{item[1]}</div>
                    </div>
                  );
                else return <></>;
              })}
            </div>
          </div> 
          <div className="w-full flex justify-between items-start">
            <div>Items</div>
            <div className="sm:w-[300px] w-[200px]">
              {orderData.items.map((item) => {
                return (
                  <div className="w-full flex justify-between items-start">
                    <div className="w-[100px] text-[#aaaaaa]">{item.name}</div>
                    <div>Quantify: {item.quantity}</div>
                    <div>C${item.retail_price}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="w-full flex justify-between items-center">
            <div>Costs</div>
            <div>
              C$
              {(
                Number(orderData.retail_costs.subtotal) * 1.3 +
                Number(orderData.retail_costs.tax) +
                Number(orderData.retail_costs.shipping)
              ).toFixed(2)}
            </div>
          </div> */}
        </>
      )}
    </div>
  );
};
