import "react-responsive-modal/styles.css";
import { Outlet } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setOrderId } from "../redux/Sidebar";

export const MerchLayout = () => {
  const stripe = useStripe();
  const dispatch = useDispatch();
  const handlePurchase = async (id) => {
    const res = (
      await axios.post(process.env.REACT_APP_PROXY_SERVER + "confirmOrder", {
        paymentId: id,
      })
    ).data;
    dispatch(setOrderId(res?.id));
    if (res?.id) {
      toast.success(
        "Order is completed successfully! Please hold your Order ID"
      );
    }
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          toast.success("Payment succeeded!");
          handlePurchase(paymentIntent.id);
          break;
        case "processing":
          toast.info("Your payment is processing.");
          break;
        case "requires_payment_method":
          toast.error("Your payment was not successful, please try again.");
          break;
        default:
          toast.error("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  return (
    <div className="pt-[100px] pb-[40px] h-[100vh]">
      <div hidden className="flex flex-col items-center mx-auto text-center">
        <div className="sm:text-[72px] sm:mt-0 mt-[20px] text-[40px] font-bold text-[#070623] flex gap-[10px]">
          BiorBank Merch Store
        </div>
        <div className="text-center sm:mt-0 mt-[20px] max-w-[80vw] text-[#667085]">
          BiorBank is the first ever Web3 banking app, for everyone
        </div>

        <Outlet />
      </div>
    </div>
  );
};
