import ReactSelect from "react-select";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import searchIcon from "../.././assets/searchIcon.svg";
import cartIcon from "../.././assets/cartIcon.svg";
import colorCartIcon from "../.././assets/coloredCartIcon.svg";
import closeIcon from "../.././assets/closeIcon.svg";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Merches } from "../../Merch/Products";
import { useLocation, useNavigate } from "react-router-dom";
import { getParamByParam } from "iso-country-currency";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/Cart";
import { ImageLoader } from "./ImageLoader";
import Skeleton from "react-loading-skeleton";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { Popover } from "react-tiny-popover";

export const Store = () => {
  const [detailModal, setShowDetailModal] = useState(false);
  const [products, setProducts] = useState([]);
  const storeId = 13767092;
  const limitCount = 8;
  const [offset, setOffset] = useState(0);
  const [totalCounts, setTotalCounts] = useState(0);
  const [currentProduct, setCurrentProduct] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [productDetails, setProductDetails] = useState();
  const [currentSubProduct, setCurrentSubProduct] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const navigate = useNavigate();
  const [currentBrand, setCurrentBrand] = useState();
  const [searchInput, setSearchInput] = useState();
  const dispatch = useDispatch();
  const location = useLocation();
  const [brandPop, setBrandPop] = useState(false);
  const [productsPop, setProductsPop] = useState(false);
  const [productsPop1, setProductsPop1] = useState(false);
  const [totalProducts, setTotalProducts] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("page");
    setCurrentPage(Number(page)); // Using 1 as the default value if page query param is not set or invalid
  }, [location.search]);

  const handlePageChange = (newPage) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("page", newPage);
    navigate(`?${searchParams.toString()}`);
  };

  const getProductsFromStore = async () => {
    try {
      const res = (
        await axios.get(
          process.env.REACT_APP_PROXY_SERVER +
            `store/products?status=all&offset=${0}&limit=${50}`
        )
      ).data;
      setProducts(res.result);
      setTotalProducts(res.result);
    } catch (error) {
      console.log(error);
    }
  };

  const getProductDetail = async (externalId) => {
    const res = (
      await axios.get(
        process.env.REACT_APP_PROXY_SERVER + "store/products/@" + externalId
      )
    ).data;
    setProductDetails(res.result);
  };

  useEffect(() => {
    if (currentProduct) getProductDetail(currentProduct);
  }, [currentProduct]);

  const filterProducts = () => {
    let temp = totalProducts.filter((item) => {
      if (currentBrand && searchInput)
        return (
          item.name.toUpperCase().includes(currentBrand.toUpperCase()) &&
          item.name.toUpperCase().includes(searchInput.toUpperCase())
        );
      else if (currentBrand)
        return item.name.toUpperCase().includes(currentBrand.toUpperCase());
      else if (searchInput)
        return item.name.toUpperCase().includes(searchInput.toUpperCase());
      else return true;
    });
    setProducts(temp);
  };

  useEffect(() => {
    filterProducts();
  }, [searchInput, currentBrand]);

  useEffect(() => {
    setSearchInput(null);
  }, [currentBrand]);

  useEffect(() => {
    setOffset(currentPage * limitCount);
    document.getElementById("merches").scrollIntoView({ behavior: "smooth" });
  }, [currentPage]);

  const [countries, setCountries] = useState([]);
  const getAvailableCountries = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_PROXY_SERVER + "countries")
    ).data;
    setCountries(res);
  };

  useEffect(() => {
    setTotalCounts(products.length);
    handlePageChange(0);
  }, [products]);

  useEffect(() => {
    getProductsFromStore();
    getAvailableCountries();
  }, []);

  const handleAddToCart = async (id, item) => {
    let files = item?.files.filter((file) => file.hash !== null);
    let cart = { ...item, quantity: 1, product_id: id, files: files };
    dispatch(addToCart(cart));
  };

  return (
    <div className="bg-white border-t-[2px] sm:border-none rounded-[20px] w-full flex flex-col items-center sm:bg-[#ffffff00] mt-[20px]">
      <div className="w-[40px] h-[4px] rounded-full bg-[#A5A5A5] my-4 sm:hidden"></div>
      <div className="w-full pl-[2vw] z-[10]">
        <Popover
          isOpen={productsPop1}
          onClickOutside={() => setProductsPop1(false)}
          positions={"bottom"}
          content={
            <div
              onClick={() => setProductsPop1(false)}
              className="mt-[4px] w-max max-h-[300px] overflow-auto bg-white rounded-[8px] border-[1px] border-[#dddddd] shadow-md"
            >
              {products.slice().map((item) => (
                <div
                  onClick={() => setSearchInput(item.name)}
                  className="flex justify-between items-center w-full text-left px-[6px] py-[10px] text-[14px] font-semibold cursor-pointer hover:bg-[#dddddd]"
                >
                  <span>{item.name}</span>
                </div>
              ))}
            </div>
          }
        >
          <div className="sm:hidden w-[86vw] bg-white flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-[8px] sm:rounded-full px-[8px] py-[0px]">
            <img alt="" src={searchIcon} />
            <input
              onClick={() => setProductsPop1(true)}
              className="outline-none my-[6px] w-full"
              value={searchInput}
              placeholder="Search for a specific item"
              onChange={(e) => setSearchInput(e.target.value)}
            />
          </div>
        </Popover>
      </div>
      <div
        id="merches"
        className="text-left sm:w-[70vw] w-full sm:mt-[40px] flex space-y-[10px] justify-between items-center"
      >
        <div className="sm:block hidden">Merch Filters</div>

        <div className="flex items-center gap-[10px] px-[10px] flex">
          <div className="w-[140px] bg-white flex items-center border-[#EAECF0] border-[2px] rounded-full pl-[8px] py-[0px]">
            <img alt="" src={searchIcon} />
            <ReactSelect
              placeholder="Country"
              styles={{
                control: () => ({
                  width: "120px",
                }),
                indicatorSeparator: () => ({
                  display: "none",
                }),
                dropdownIndicator: () => ({
                  display: "none",
                }),
                option: (provided) => ({
                  ...provided,
                  color: "black",
                  fontSize: "12px",
                  ":hover": {
                    backgroundColor: "#ddd",
                  },
                  backgroundColor: "white",
                }),
              }}
              options={countries.slice().map((item) => {
                return {
                  label: item.name,
                  value: item.name,
                };
              })}
              // onChange={(option) => setSearchInput(option.label)}
            />
          </div>
          <Popover
            isOpen={brandPop}
            onClickOutside={() => setBrandPop(false)}
            content={
              <div
                onClick={() => setBrandPop(false)}
                className="mt-[4px] sm:w-[150px] w-[120px] bg-white rounded-[8px] border-[1px] border-[#dddddd] shadow-md"
              >
                {["Chainflip", "Maya", "THORChain", "All"].map((brand) => (
                  <div
                    onClick={() => {
                      if (brand === "All") setCurrentBrand(null);
                      else setCurrentBrand(brand);
                    }}
                    className="flex justify-between items-center w-full text-left px-[6px] py-[10px] text-[14px] font-semibold cursor-pointer hover:bg-[#dddddd]"
                  >
                    <span>{brand}</span>
                    {brand === currentBrand ||
                    (brand === "All" && !currentBrand) ? (
                      <div className="w-[20px] h-[20px] rounded-full border-[1px] p-[4px] border-[#2E30B2]">
                        <div className="w-full h-full rounded-full bg-[#2E30B2]" />
                      </div>
                    ) : (
                      <div className="w-[20px] h-[20px] rounded-full border-[1px] p-[4px] border-[#bbbbbb]"></div>
                    )}
                  </div>
                ))}
              </div>
            }
            positions={"bottom"}
          >
            <div
              onClick={() => setBrandPop(true)}
              className="sm:w-[160px] w-[130px] bg-white flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-full px-[10px] py-[6px]"
            >
              {currentBrand ? currentBrand : "Brand"}
            </div>
          </Popover>

          <Popover
            isOpen={productsPop}
            onClickOutside={() => setProductsPop(false)}
            positions={"bottom"}
            content={
              <div
                onClick={() => setProductsPop(false)}
                className="mt-[4px] w-[290px] max-h-[300px] overflow-auto bg-white rounded-[8px] border-[1px] border-[#dddddd] shadow-md"
              >
                {products.slice().map((item) => (
                  <div
                    onClick={() => setSearchInput(item.name)}
                    className="flex justify-between items-center w-full text-left px-[6px] py-[10px] text-[14px] font-semibold cursor-pointer hover:bg-[#dddddd]"
                  >
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            }
          >
            <div className="z-[10] hidden sm:w-[300px] w-full sm:bg-white bg-[#F6F8FC] sm:flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-[8px] sm:rounded-full px-[8px] py-[0px]">
              <img alt="" src={searchIcon} />
              <input
                onClick={() => setProductsPop(true)}
                className="outline-none my-[6px] w-full"
                value={searchInput}
                placeholder="Search for a specific item"
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </div>
          </Popover>
          <img
            alt=""
            src={cartIcon}
            className="cursor-pointer z-[10]"
            onClick={() => {
              navigate("/merch/cart");
            }}
          />
        </div>
      </div>
      <div className="sm:w-[80vw] px-[10px] w-full mt-[60px] grid sm:grid-cols-4 grid-cols-2 sm:gap-[60px] gap-[10px]">
        {products
          ?.slice(
            limitCount * currentPage,
            limitCount * currentPage + limitCount
          )
          ?.map((item) => {
            return (
              <div
                onClick={() => {
                  setCurrentSubProduct(0);
                  setImageIndex(0);
                  setCurrentProduct(item.external_id);
                  setShowDetailModal(true);
                }}
                className="cursor-pointer bg-[#F6F5FA] border-[#e9e9e9] border-[2px] rounded-[12px] w-full mx-auto p-[10px] relative "
              >
                <ImageLoader src={item.thumbnail_url} />
                <div className="w-full mt-[20px] sm:text-[24px] text-[20px] text-left">
                  {Merches[item.external_id]?.price.toFixed(2) + "$"}
                </div>
                <div className="w-full mb-[10px] text-left">{item.name}</div>
              </div>
            );
          })}
        {!products.length && [0, 1, 2, 3].map(() => <Skeleton height={300} />)}
      </div>
      <div className="mt-[40px] flex gap-[14px]">
        {Array.from(Array(Math.ceil(totalCounts / limitCount)).keys())
          // .slice(page.start, page.end)
          .map((it, i) => {
            return (
              <div
                onClick={() => {
                  handlePageChange(it);
                }}
                className={
                  it === currentPage
                    ? "select-none cursor-pointer w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#202020]"
                    : "select-none cursor-pointer w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#dddddd]"
                }
              >
                {it + 1}
              </div>
            );
          })}
        {/* <div
            onClick={() => {
              if (currentPage === Math.floor(totalCounts / limitCount)) return;
              setCurrentPage(currentPage + 1);
            }}
            className={
              currentPage !== Math.floor(totalCounts / limitCount)
                ? "cursor-pointer w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#202020]"
                : "cursor-not-allowed w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#dddddd]"
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M10 16L14 12L10 8"
                stroke="#202020"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div> */}
      </div>
      <div className="my-[20px] select-none">
        Page {currentPage + 1} of {Math.ceil(totalCounts / limitCount)}
      </div>

      <Modal
        open={detailModal}
        onClose={() => {
          setShowDetailModal(false);
        }}
        center
        classNames={{
          modal:
            "sm:min-w-[900px] p-[20px] min-h-[50vh] bg-white rounded-[8px] relative",
        }}
        // showCloseIcon={false}
        closeIcon={<img alt="" src={closeIcon} className="w-[30px]" />}
      >
        {currentProduct && (
          <div className="h-[100%] flex flex-col font-semibold">
            <div className="text-[18px] font-semibold">Details</div>
            <div className="grid sm:grid-cols-2 gap-[20px] h-full">
              <div className="min-w-[50%] flex flex-col justify-center h-full items-center space-y-[50px]">
                <img
                  alt=""
                  src={Merches[currentProduct]?.images[imageIndex]}
                  className="sm:w-[400px] w-full mx-auto rounded-[10px] sm:mt-0 mt-[10px]"
                />
              </div>
              <div className="sm:hidden">
                <div className="mx-auto flex items-center gap-[10px] w-full max-w-[70vw] pb-[10px] overflow-auto scrollbarwidth">
                  {Merches[currentProduct]?.images.map((data, i) => {
                    return (
                      <div
                        onClick={() => setImageIndex(i)}
                        className={
                          i === imageIndex
                            ? "cursor-pointer min-w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#202020]"
                            : "cursor-pointer min-w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#dddddd]"
                        }
                      >
                        {i + 1}
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="h-full flex sm:flex-col flex-col-reverse justify-between sm:w-[400px]">
                <div className="flex flex-col space-y-[10px]">
                  <div className="text-[#070623] text-[18px] font-semibold">
                    Description
                  </div>

                  {productDetails?.sync_variants.slice().map((detail, i) => {
                    const array = [];
                    if (i === currentSubProduct)
                      array.push(
                        <>
                          <div className="text-[#A5A5A5]">
                            {detail.product.name}
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="text-[#A5A5A5]">
                              Price ({detail.currency})
                            </div>
                            <div className="text-[#070623]">
                              ${detail.retail_price}
                            </div>
                          </div>
                          <div className="flex gap-[10px] items-start flex-wrap">
                            <div className="space-y-[6px]">
                              <div className="text-[#A5A5A5]">Size - Color</div>
                              <div className="bg-white flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-full px-[8px]">
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      display: "flex",
                                      width: "200px",
                                    }),
                                    indicatorSeparator: () => ({
                                      display: "none",
                                    }),

                                    option: (provided) => ({
                                      ...provided,
                                      color: "black",

                                      ":hover": {
                                        backgroundColor: "#ddd",
                                      },
                                      backgroundColor: "white",
                                    }),
                                  }}
                                  value={{
                                    label:
                                      productDetails?.sync_variants[
                                        currentSubProduct
                                      ].size +
                                      "-" +
                                      productDetails?.sync_variants[
                                        currentSubProduct
                                      ].color,
                                    value: currentSubProduct,
                                  }}
                                  options={productDetails?.sync_variants
                                    .slice()
                                    .map((size, j) => {
                                      return {
                                        value: j,
                                        label:
                                          (j + 1).toString() +
                                          ". " +
                                          size.size +
                                          "-" +
                                          size.color,
                                      };
                                    })}
                                  isSearchable={false}
                                  onChange={(option) => {
                                    setCurrentSubProduct(option.value);
                                  }}
                                />
                              </div>
                            </div>
                            {/* <div className="space-y-[6px]">
                              <div className="text-[#A5A5A5]">Color:</div>

                              <div className="bg-white flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-full px-[8px]">
                                <ReactSelect
                                  styles={{
                                    control: () => ({
                                      display: "flex",
                                      width: "100px",
                                    }),
                                    indicatorSeparator: () => ({
                                      display: "none",
                                    }),

                                    option: (provided) => ({
                                      ...provided,
                                      color: "black",

                                      ":hover": {
                                        backgroundColor: "#ddd",
                                      },
                                      backgroundColor: "white",
                                    }),
                                  }}
                                  value={{
                                    label:
                                      productDetails?.sync_variants[
                                        currentSubProduct
                                      ].color,
                                    value: currentSubProduct,
                                  }}
                                  options={productDetails?.sync_variants
                                    .slice()
                                    .map((color, j) => {
                                      return {
                                        value: j,
                                        label:
                                          (j + 1).toString() +
                                          ". " +
                                          color.color,
                                      };
                                    })}
                                  isSearchable={false}
                                  onChange={(option) => {
                                    setCurrentSubProduct(option.value);
                                  }}
                                />
                              </div>
                            </div> */}
                            <div className="space-y-[6px]">
                              <div className="text-[#A5A5A5]">Brand:</div>
                              <div className="w-max bg-white flex items-center gap-[10px] border-[#EAECF0] border-[2px] rounded-full px-[10px] py-[6px]">
                                <div className="max-w-[100px] truncate">
                                  {productDetails.sync_product.name}
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    return array;
                  })}

                  <div className="text-[18px] font-semibold">
                    Product Details
                  </div>

                  {Merches[currentProduct]?.details && (
                    <>
                      <div className="flex items-center gap-[10px]">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M20 10V5C20 3.89543 19.1046 3 18 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H10"
                            stroke="#323232"
                            stroke-width="1.8"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 7H16"
                            stroke="#323232"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 11H13"
                            stroke="#323232"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7 15H9"
                            stroke="#323232"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M14.0391 15.6191V20.0001C14.0407 20.5518 14.4874 20.9985 15.0391 21.0001H20.0391C20.5913 21.0001 21.0391 20.5524 21.0391 20.0001V15.6877"
                            stroke="#323232"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M22 16.5L18.1462 13.2368C17.7733 12.9211 17.2267 12.9211 16.8538 13.2368L13 16.5"
                            stroke="#323232"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>

                        <div>Details</div>
                      </div>
                      <div className="space-y-4px]">
                        {Object.entries(Merches[currentProduct].details).map(
                          (item) => {
                            return (
                              <div className="text-[#a5a5a5]">
                                {item[1] !== ""
                                  ? item[0] + " : " + item[1]
                                  : item[0]}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <hr />
                    </>
                  )}

                  {Merches[currentProduct]?.material && (
                    <>
                      <div className="flex items-center gap-[10px]">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.7904 12.4523C11.2504 12.4523 10.7004 12.3523 10.2704 12.1623L4.37039 9.54227C2.87039 8.87227 2.65039 7.97227 2.65039 7.48227C2.65039 6.99227 2.87039 6.09227 4.37039 5.42227L10.2704 2.80227C11.1404 2.41227 12.4504 2.41227 13.3204 2.80227L19.2304 5.42227C20.7204 6.08227 20.9504 6.99227 20.9504 7.48227C20.9504 7.97227 20.7304 8.87227 19.2304 9.54227L13.3204 12.1623C12.8804 12.3623 12.3404 12.4523 11.7904 12.4523ZM11.7904 4.01227C11.4504 4.01227 11.1204 4.06227 10.8804 4.17227L4.98039 6.79227C4.37039 7.07227 4.15039 7.35227 4.15039 7.48227C4.15039 7.61227 4.37039 7.90227 4.97039 8.17227L10.8704 10.7923C11.3504 11.0023 12.2204 11.0023 12.7004 10.7923L18.6104 8.17227C19.2204 7.90227 19.4404 7.61227 19.4404 7.48227C19.4404 7.35227 19.2204 7.06227 18.6104 6.79227L12.7104 4.17227C12.4704 4.07227 12.1304 4.01227 11.7904 4.01227Z"
                            fill="#292D32"
                          />
                          <path
                            d="M12 17.6623C11.62 17.6623 11.24 17.5823 10.88 17.4223L4.09 14.4023C3.06 13.9523 2.25 12.7023 2.25 11.5723C2.25 11.1623 2.59 10.8223 3 10.8223C3.41 10.8223 3.75 11.1623 3.75 11.5723C3.75 12.1223 4.2 12.8123 4.7 13.0423L11.49 16.0623C11.81 16.2023 12.18 16.2023 12.51 16.0623L19.3 13.0423C19.8 12.8223 20.25 12.1223 20.25 11.5723C20.25 11.1623 20.59 10.8223 21 10.8223C21.41 10.8223 21.75 11.1623 21.75 11.5723C21.75 12.7023 20.94 13.9523 19.91 14.4123L13.12 17.4323C12.76 17.5823 12.38 17.6623 12 17.6623Z"
                            fill="#292D32"
                          />
                          <path
                            d="M12 22.6625C11.62 22.6625 11.24 22.5825 10.88 22.4225L4.09 19.4025C2.97 18.9025 2.25 17.7925 2.25 16.5625C2.25 16.1525 2.59 15.8125 3 15.8125C3.41 15.8125 3.75 16.1625 3.75 16.5725C3.75 17.2025 4.12 17.7825 4.7 18.0425L11.49 21.0625C11.81 21.2025 12.18 21.2025 12.51 21.0625L19.3 18.0425C19.88 17.7825 20.25 17.2125 20.25 16.5725C20.25 16.1625 20.59 15.8225 21 15.8225C21.41 15.8225 21.75 16.1625 21.75 16.5725C21.75 17.8025 21.03 18.9125 19.91 19.4125L13.12 22.4325C12.76 22.5825 12.38 22.6625 12 22.6625Z"
                            fill="#292D32"
                          />
                        </svg>
                        <div>Material</div>
                      </div>
                      <div className="space-y-4px]">
                        {Object.entries(Merches[currentProduct].material).map(
                          (item) => {
                            return (
                              <div className="text-[#a5a5a5]">
                                {item[1] !== ""
                                  ? item[0] + " : " + item[1]
                                  : item[0]}
                              </div>
                            );
                          }
                        )}
                      </div>
                      <hr />
                    </>
                  )}
                  {false && (
                    <>
                      <div className="text-[#A5A5A5] font-semibold text-[16px]">
                        Fabric Thickness
                      </div>
                      <svg
                        className="w-full"
                        height="5"
                        viewBox="0 0 464 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="464"
                          height="4"
                          rx="2"
                          transform="matrix(1 0 0 -1 0 4.57227)"
                          fill="#F1F5F9"
                        />
                        <rect
                          width="232"
                          height="4"
                          rx="2"
                          transform="matrix(1 0 0 -1 0 4.57227)"
                          fill="#0F172A"
                        />
                      </svg>
                      <div className="w-full flex justify-between">
                        <div className="text-[#A5A5A5] text-[14px]">
                          Lightweight
                        </div>
                        <div className="text-[#A5A5A5] text-[14px]">
                          Heavyweight
                        </div>
                      </div>
                    </>
                  )}

                  {Merches[currentProduct]["Style and fit"] && (
                    <>
                      <div className="flex items-center gap-[10px]">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15 23.3223H9C3.57 23.3223 1.25 21.0023 1.25 15.5723V9.57227C1.25 4.14227 3.57 1.82227 9 1.82227H15C20.43 1.82227 22.75 4.14227 22.75 9.57227V15.5723C22.75 21.0023 20.43 23.3223 15 23.3223ZM9 3.32227C4.39 3.32227 2.75 4.96227 2.75 9.57227V15.5723C2.75 20.1823 4.39 21.8223 9 21.8223H15C19.61 21.8223 21.25 20.1823 21.25 15.5723V9.57227C21.25 4.96227 19.61 3.32227 15 3.32227H9Z"
                            fill="#292D32"
                          />
                          <path
                            d="M8.41998 12.223C6.94998 12.223 5.75 11.023 5.75 9.55304C5.75 8.08304 6.94998 6.87305 8.41998 6.87305C9.88998 6.87305 11.09 8.07304 11.09 9.55304C11.09 11.033 9.89998 12.223 8.41998 12.223ZM8.41998 8.37305C7.76998 8.37305 7.25 8.90304 7.25 9.55304C7.25 10.203 7.77998 10.723 8.41998 10.723C9.05998 10.723 9.59003 10.193 9.59003 9.55304C9.59003 8.91304 9.06998 8.37305 8.41998 8.37305Z"
                            fill="#292D32"
                          />
                          <path
                            d="M8.41998 18.2719C6.94998 18.2719 5.75 17.0719 5.75 15.5919C5.75 14.1119 6.94998 12.9219 8.41998 12.9219C9.88998 12.9219 11.09 14.1219 11.09 15.5919C11.09 17.0619 9.89998 18.2719 8.41998 18.2719ZM8.41998 14.4219C7.76998 14.4219 7.25 14.9519 7.25 15.5919C7.25 16.2319 7.77998 16.7719 8.41998 16.7719C9.05998 16.7719 9.59003 16.2419 9.59003 15.5919C9.59003 14.9419 9.06998 14.4219 8.41998 14.4219Z"
                            fill="#292D32"
                          />
                          <path
                            d="M10.1604 15.5118C9.9304 15.5118 9.71042 15.4118 9.56042 15.2118C9.31042 14.8818 9.38044 14.4117 9.71044 14.1617L17.0504 8.67176C17.3804 8.42176 17.8504 8.49175 18.1004 8.82175C18.3504 9.15175 18.2804 9.62176 17.9504 9.87176L10.6104 15.3617C10.4704 15.4617 10.3204 15.5118 10.1604 15.5118Z"
                            fill="#292D32"
                          />
                          <path
                            d="M17.5004 16.6016C17.3404 16.6016 17.1904 16.5516 17.0504 16.4516L9.71044 10.9616C9.38044 10.7116 9.31042 10.2416 9.56042 9.9116C9.81042 9.5816 10.2804 9.5116 10.6104 9.7616L17.9504 15.2516C18.2804 15.5016 18.3504 15.9716 18.1004 16.3016C17.9504 16.5016 17.7304 16.6016 17.5004 16.6016Z"
                            fill="#292D32"
                          />
                        </svg>

                        <div>Style and fit</div>
                      </div>
                      <div className="space-y-4px]">
                        {Object.entries(
                          Merches[currentProduct]["Style and fit"]
                        ).map((item) => {
                          return (
                            <div className="text-[#a5a5a5]">
                              {item[1] !== ""
                                ? item[0] + " : " + item[1]
                                : item[0]}
                            </div>
                          );
                        })}
                      </div>
                      <hr />
                    </>
                  )}

                  {Merches[currentProduct].features && (
                    <>
                      <div className="flex items-center gap-[10px]">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 17.3223C11.6 17.3223 11.2 17.2923 10.82 17.2223C8.7 16.9123 6.77 15.6923 5.55 13.8823C4.7 12.6023 4.25 11.1123 4.25 9.57227C4.25 5.30227 7.73 1.82227 12 1.82227C16.27 1.82227 19.75 5.30227 19.75 9.57227C19.75 11.1123 19.3 12.6023 18.45 13.8823C17.22 15.7023 15.29 16.9123 13.15 17.2323C12.8 17.2923 12.4 17.3223 12 17.3223ZM12 3.32227C8.55 3.32227 5.75 6.12227 5.75 9.57227C5.75 10.8223 6.11 12.0223 6.79 13.0423C7.78 14.5023 9.33 15.4823 11.05 15.7323C11.69 15.8423 12.32 15.8423 12.91 15.7323C14.66 15.4823 16.21 14.4923 17.2 13.0323C17.88 12.0123 18.24 10.8123 18.24 9.56226C18.25 6.12226 15.45 3.32227 12 3.32227Z"
                            fill="#292D32"
                          />
                          <path
                            d="M6.46933 23.1622C6.32933 23.1622 6.19933 23.1422 6.05933 23.1122C5.40933 22.9622 4.90933 22.4622 4.75933 21.8122L4.40933 20.3422C4.38933 20.2522 4.31933 20.1822 4.21933 20.1522L2.56933 19.7622C1.94933 19.6122 1.45933 19.1522 1.28933 18.5422C1.11933 17.9322 1.28933 17.2722 1.73933 16.8222L5.63933 12.9222C5.79933 12.7622 6.01933 12.6822 6.23933 12.7022C6.45933 12.7222 6.65933 12.8422 6.78933 13.0322C7.77933 14.4922 9.32933 15.4822 11.0593 15.7322C11.6993 15.8422 12.3293 15.8422 12.9193 15.7322C14.6693 15.4822 16.2193 14.4922 17.2093 13.0322C17.3293 12.8422 17.5393 12.7222 17.7593 12.7022C17.9793 12.6822 18.1993 12.7622 18.3593 12.9222L22.2593 16.8222C22.7093 17.2722 22.8793 17.9322 22.7093 18.5422C22.5393 19.1522 22.0393 19.6222 21.4293 19.7622L19.7793 20.1522C19.6893 20.1722 19.6193 20.2422 19.5893 20.3422L19.2393 21.8122C19.0893 22.4622 18.5893 22.9622 17.9393 23.1122C17.2893 23.2722 16.6193 23.0422 16.1993 22.5322L11.9993 17.7022L7.79933 22.5422C7.45933 22.9422 6.97933 23.1622 6.46933 23.1622ZM6.08933 14.6022L2.79933 17.8922C2.70933 17.9822 2.71933 18.0822 2.73933 18.1422C2.74933 18.1922 2.79933 18.2922 2.91933 18.3122L4.56933 18.7022C5.21933 18.8522 5.71933 19.3522 5.86933 20.0022L6.21933 21.4722C6.24933 21.6022 6.34933 21.6422 6.40933 21.6622C6.46933 21.6722 6.56933 21.6822 6.65933 21.5822L10.4893 17.1722C8.78933 16.8422 7.22933 15.9322 6.08933 14.6022ZM13.5093 17.1622L17.3393 21.5622C17.4293 21.6722 17.5393 21.6722 17.5993 21.6522C17.6593 21.6422 17.7493 21.5922 17.7893 21.4622L18.1393 19.9922C18.2893 19.3422 18.7893 18.8422 19.4393 18.6922L21.0893 18.3022C21.2093 18.2722 21.2593 18.1822 21.2693 18.1322C21.2893 18.0822 21.2993 17.9722 21.2093 17.8822L17.9193 14.5922C16.7693 15.9222 15.2193 16.8322 13.5093 17.1622Z"
                            fill="#292D32"
                          />
                          <path
                            d="M13.8911 13.4625C13.6311 13.4625 13.3211 13.3925 12.9511 13.1725L12.0011 12.6025L11.0511 13.1625C10.1811 13.6825 9.61112 13.3825 9.40112 13.2325C9.19112 13.0825 8.74112 12.6325 8.97112 11.6425L9.21112 10.6125L8.41112 9.8725C7.97112 9.4325 7.81112 8.90252 7.96112 8.42252C8.11112 7.94252 8.55112 7.6025 9.17112 7.5025L10.2411 7.32251L10.7511 6.20251C11.0411 5.63251 11.4911 5.3125 12.0011 5.3125C12.5111 5.3125 12.9711 5.64252 13.2511 6.21252L13.8411 7.39252L14.8311 7.51251C15.4411 7.61251 15.8811 7.9525 16.0411 8.4325C16.1911 8.9125 16.0311 9.44251 15.5911 9.88251L14.7611 10.7125L15.0211 11.6425C15.2511 12.6325 14.8011 13.0825 14.5911 13.2325C14.4811 13.3225 14.2411 13.4625 13.8911 13.4625ZM9.61112 8.96252L10.3011 9.6525C10.6211 9.9725 10.7811 10.5125 10.6811 10.9525L10.4911 11.7525L11.2911 11.2825C11.7211 11.0325 12.3011 11.0325 12.7211 11.2825L13.5211 11.7525L13.3411 10.9525C13.2411 10.5025 13.3911 9.9725 13.7111 9.6525L14.4011 8.96252L13.5311 8.8125C13.1111 8.7425 12.6911 8.43252 12.5011 8.05252L12.0011 7.07251L11.5011 8.07251C11.3211 8.44251 10.9011 8.76252 10.4811 8.83252L9.61112 8.96252Z"
                            fill="#292D32"
                          />
                        </svg>

                        <div>Features</div>
                      </div>
                      <div className="space-y-4px]">
                        {Object.entries(Merches[currentProduct].features).map(
                          (item) => {
                            return (
                              <div className="text-[#a5a5a5]">
                                {item[1] !== ""
                                  ? item[0] + " : " + item[1]
                                  : item[0]}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="w-full h-full">
                  <div
                    onClick={async () => {
                      await handleAddToCart(
                        productDetails?.sync_product?.external_id,
                        productDetails?.sync_variants[currentSubProduct]
                      );
                      setShowDetailModal(false);
                      navigate("/merch/cart");
                    }}
                    className="sm:mt-[40px] cursor-pointer text-white text-center w-full py-[10px] rounded-[12px] bg-gradient-to-tr from-[#2E31B7] to-[#1C1460]"
                  >
                    Purchase
                  </div>
                  <div
                    onClick={() => {
                      handleAddToCart(
                        productDetails.sync_product.external_id,
                        productDetails.sync_variants[currentSubProduct]
                      );
                      setShowDetailModal(false);
                    }}
                    className="mt-[10px] mb-[10px] flex items-center justify-center cursor-pointer text-white text-center w-full py-[10px] rounded-[12px] border-[1px] border-[#2A2AA0]"
                  >
                    <img alt="" src={colorCartIcon} />
                    <div className="text-[#2E30B2]">Add to cart</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="absolute sm:block hidden bottom-[20px] w-[40%]">
          <div className="flex items-center gap-[10px] w-full pb-[10px] overflow-auto scrollbarwidth">
            {Merches[currentProduct]?.images.map((data, i) => {
              return (
                <div
                  onClick={() => setImageIndex(i)}
                  className={
                    i === imageIndex
                      ? "cursor-pointer min-w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#202020]"
                      : "cursor-pointer min-w-[40px] h-[40px] flex justify-center items-center rounded-[4px] border-[1px] border-[#dddddd]"
                  }
                >
                  {i + 1}
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    </div>
  );
};
