import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  mode: null,
  totalAmountToPay: 0,
  purchasable: null,
  orderId: null,
};

export const SideBarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    setSidebarShow: (state, action) => {
      state.show = action.payload;
    },
    setSidebarMode: (state, action) => {
      state.mode = action.payload;
    },
    setTotalAmountToPay: (state, action) => {
      state.totalAmountToPay = action.payload;
    },
    setPurchasable: (state, action) => {
      state.purchasable = action.payload;
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload;
    },
  },
});

export const {
  setSidebarShow,
  setSidebarMode,
  setTotalAmountToPay,
  setPurchasable,
  setOrderId,
} = SideBarSlice.actions;

export default SideBarSlice.reducer;
