import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Merches } from "../../Merch/Products";
import { getParamByParam } from "iso-country-currency";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addToCart } from "../../redux/Cart";
import { useLockBodyScroll } from "@uidotdev/usehooks";
import { updateRecipient } from "../../redux/Recipient";
import {
  setPurchasable,
  setSidebarMode,
  setSidebarShow,
  setTotalAmountToPay,
} from "../../redux/Sidebar";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

export const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const [cartItems, setCartItems] = useState([]);
  const [updated, setUpdated] = useState(false);

  const [subTotal, setSubTotal] = useState(0);
  const [shipping, setShipping] = useState(0);
  const [tax, setTax] = useState(0);
  const [totalCounts, setTotalCounts] = useState(0);
  const estimateTotal = useSelector((state) => state.sidebar.totalAmountToPay);
  const orderId = useSelector((state) => state.sidebar.orderId);
  const cartItems = useSelector((state) => state.cart.items);
  const recipient = useSelector((state) => state.recipient.recipient);
  const [state, setState] = useState({
    ip: "",
    countryName: "",
    countryCode: "",
    city: "",
    timezone: "",
  });
  const [currency, setCurrency] = useState("USD");
  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setState({
          ...state,
          ip: data.ip,
          countryName: data.country_name,
          countryCode: data.country_calling_code,
          city: data.city,
          timezone: data.timezone,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    try {
      setCurrency(
        getParamByParam("countryName", state.countryName, "currency")
      );
    } catch (error) {}
  }, [state]);

  useEffect(() => {
    getGeoInfo();
  }, []);

  // const getCart = async () => {
  //   const res = (
  //     await axios.post(process.env.REACT_APP_PROXY_SERVER + "getCartItems", {
  //       email: "example@email.com",
  //       currency: currency,
  //     })
  //   ).data;
  //   for (let i = 0; i < res.shippingRate?.length; i++) {
  //     if (res.shippingRate[i].id === "STANDARD")
  //       setShipping(res.shippingRate[i].rate);
  //   }
  //   setTax(res.taxRate.rate);
  //   setCartItems(Object.values(res.items));
  //   setTotalCounts(Object.values(res.items).length);
  // };

  const getAvailableCountries = async () => {
    const res = (
      await axios.get(process.env.REACT_APP_PROXY_SERVER + "countries")
    ).data;
  };

  const estimateOrderCost = async (recipient, cartItems) => {
    try {
      if (!Object.keys(recipient).length || !Object.keys(cartItems).length)
        return;
      const res = (
        await axios.post(
          process.env.REACT_APP_PROXY_SERVER + "estimateOrderCost",
          {
            recipient: recipient,
            items: Object.values(cartItems),
          }
        )
      ).data;
      // console.log(res.result);
      if (!res.result) {
        toast.error("Invalid address input or unavailable product");
        dispatch(setPurchasable(false));
        return;
      }
      setSubTotal(res.result.retail_costs.subtotal);
      setShipping(res.result.retail_costs.shipping);
      setTax(res.result.retail_costs.tax);
      // setEstimateTotal(res.result.retail_costs.total);
      dispatch(
        setTotalAmountToPay(
          (
            res.result.retail_costs.total +
            res.result.retail_costs.total * 0.3
          ).toFixed(2)
        )
      );
      dispatch(setPurchasable(true));
    } catch (error) {
      toast.error("Invalid address input or unavailable product");
      dispatch(setPurchasable(false));
    }
  };

  useEffect(() => {
    setTotalCounts(Object.keys(cartItems).length);
    estimateOrderCost(recipient, cartItems);
    if (Object.keys(cartItems).length === 0) {
      // dispatch(setPurchasable(false));
      dispatch(setTotalAmountToPay(0));
    }
  }, [recipient, cartItems]);

  useEffect(() => {
    let subtotal = 0;
    const items = Object.values(cartItems);
    for (let i = 0; i < items.length; i++) {
      subtotal += items[i].retail_price * items[i].quantity;
    }
    setSubTotal(subtotal);
  }, [cartItems]);

  const handleAddToCart = async (id, item, quantity) => {
    let cart = { ...item, quantity: quantity, product_id: id };
    dispatch(addToCart(cart));

    // const res = (
    //   await axios.post(process.env.REACT_APP_PROXY_SERVER + "addToCart", {
    //     email: "example@email.com",
    //     cart: cart,
    //   })
    // ).data;
    // if (res) setUpdated(!updated);
  };
  const [recipientShow, setRecipientShow] = useState(false);
  const [isVisible, setIsVisible] = useState();
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (isVisible) {
      if (recipientShow === false) {
        setTimeout(() => {
          setIsVisible(false);
        }, 150);
        setWidth(0);
      }
    } else {
      if (recipientShow) {
        setIsVisible(true);
      }
    }
  }, [recipientShow, isVisible]);

  useEffect(() => {
    if (isVisible) setWidth(80);
  }, [isVisible]);

  return (
    <>
      <div className="border-t-[2px] sm:border-none rounded-[20px] bg-white sm:bg-[#ffffff00] sm:mt-[100px] sm:w-[80vw] w-full flex flex-col sm:flex-row sm:items-start sm:gap-[20px] items-center z-[10] my-[20px]">
        <div className="w-[40px] h-[4px] rounded-full bg-[#A5A5A5] mt-4 sm:hidden"></div>
        <div className="hidden sm:block border-[#EAECF0] w-full pl-[12px] py-[8px] border-[1px] rounded-[8px] bg-white">
          <div className="flex items-center pb-[10px] gap-[4px]">
            <svg
              onClick={() => navigate(-1)}
              className="cursor-pointer"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.97422 15.6829C7.81589 15.6829 7.65755 15.6246 7.53255 15.4996L2.47422 10.4413C2.23255 10.1996 2.23255 9.79961 2.47422 9.55794L7.53255 4.49961C7.77422 4.25794 8.17422 4.25794 8.41589 4.49961C8.65755 4.74128 8.65755 5.14128 8.41589 5.38294L3.79922 9.99961L8.41589 14.6163C8.65755 14.8579 8.65755 15.2579 8.41589 15.4996C8.29922 15.6246 8.13255 15.6829 7.97422 15.6829Z"
                fill="#292D32"
              />
              <path
                d="M17.0836 10.625H3.05859C2.71693 10.625 2.43359 10.3417 2.43359 10C2.43359 9.65833 2.71693 9.375 3.05859 9.375H17.0836C17.4253 9.375 17.7086 9.65833 17.7086 10C17.7086 10.3417 17.4253 10.625 17.0836 10.625Z"
                fill="#292D32"
              />
            </svg>
            <div className="text-[20px]">Cart</div>
            <div className="text-[#A5A5A5] text-[10px] mt-[6px]">
              ({totalCounts} items)
            </div>
          </div>
          <tr className="sm:table-row hidden bg-[#f5f5f5] h-[50px] text-[14px] align-middle rounded-t-[10px]">
            <td className="px-[10px]">#</td>
            <td className="min-w-[300px] pl-[10px]">Items</td>
            <td className="min-w-[110px] px-[10px]">Price (CAD)</td>
            <td className="w-full"></td>
          </tr>
          <tr className="sm:hidden table ml-[-12px] bg-[#f5f5f5] h-[50px] text-[14px] align-middle rounded-t-[10px]">
            <td className="px-[10px]">#</td>
            <td className="w-full pl-[10px]">Items</td>
          </tr>
          {Object.values(cartItems).map((item, i) => {
            if (item)
              return (
                <>
                  <tr className="sm:table-row hidden text-center h-[100px] align-middle">
                    <td>{i + 1}</td>
                    <td>
                      <div className="flex gap-[10px] justify-center">
                        <img
                          alt=""
                          className="w-[40px]"
                          src={Merches[item.product_id].images[0]}
                        />
                        <div>
                          <div className="w-[200px] truncate">{item.name}</div>
                          <div className="flex items-center gap-[20px] bg-[#F7F8FA] px-[4px] rounded-full w-max">
                            <svg
                              onClick={() =>
                                handleAddToCart(item.product_id, item, -1)
                              }
                              className="cursor-pointer"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.48787 14.9274C3.9903 14.9274 1.13672 11.8208 1.13672 7.99884C1.13672 4.17687 3.9903 1.07031 7.48787 1.07031C10.9854 1.07031 13.839 4.17687 13.839 7.99884C13.839 11.8208 10.9913 14.9274 7.48787 14.9274ZM7.48787 2.03708C4.47476 2.03708 2.02293 4.71182 2.02293 7.99884C2.02293 11.2859 4.47476 13.9606 7.48787 13.9606C10.501 13.9606 12.9528 11.2859 12.9528 7.99884C12.9528 4.71182 10.501 2.03708 7.48787 2.03708Z"
                                fill="#292D32"
                              />
                              <path
                                d="M9.85118 8.4824H5.12474C4.88251 8.4824 4.68164 8.26326 4.68164 7.99901C4.68164 7.73476 4.88251 7.51562 5.12474 7.51562H9.85118C10.0934 7.51562 10.2943 7.73476 10.2943 7.99901C10.2943 8.26326 10.0993 8.4824 9.85118 8.4824Z"
                                fill="#292D32"
                              />
                            </svg>
                            <div>{item.quantity}</div>
                            <svg
                              onClick={() =>
                                handleAddToCart(item.product_id, item, 1)
                              }
                              className="cursor-pointer"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.46443 14.3507C3.96096 14.3507 1.11328 11.5031 1.11328 7.99959C1.11328 4.49612 3.96096 1.64844 7.46443 1.64844C10.9679 1.64844 13.8156 4.49612 13.8156 7.99959C13.8156 11.5031 10.9679 14.3507 7.46443 14.3507ZM7.46443 2.53464C4.45133 2.53464 1.99949 4.98648 1.99949 7.99959C1.99949 11.0127 4.45133 13.4645 7.46443 13.4645C10.4775 13.4645 12.9294 11.0127 12.9294 7.99959C12.9294 4.98648 10.4775 2.53464 7.46443 2.53464Z"
                                fill="#292D32"
                              />
                              <path
                                d="M9.82774 8.44285H5.10131C4.85908 8.44285 4.6582 8.24197 4.6582 7.99974C4.6582 7.75751 4.85908 7.55664 5.10131 7.55664H9.82774C10.07 7.55664 10.2708 7.75751 10.2708 7.99974C10.2708 8.24197 10.07 8.44285 9.82774 8.44285Z"
                                fill="#292D32"
                              />
                              <path
                                d="M7.46459 10.806C7.22236 10.806 7.02148 10.6051 7.02148 10.3629V5.63646C7.02148 5.39423 7.22236 5.19336 7.46459 5.19336C7.70682 5.19336 7.90769 5.39423 7.90769 5.63646V10.3629C7.90769 10.6051 7.70682 10.806 7.46459 10.806Z"
                                fill="#292D32"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>${(item.retail_price * item.quantity).toFixed(2)}</td>
                    <td>
                      <div className="flex flex-col items-start">
                        <div>{item.name}</div>
                        <div
                          onClick={() =>
                            handleAddToCart(item.product_id, item, -10000)
                          }
                          className="text-[#a5a5a5] text-[14px] cursor-pointer"
                        >
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="sm:hidden text-center h-[100px] align-middle">
                    <td className="">{i + 1}</td>
                    <td className="flex items-start flex-col space-y-[6px] pl-[10px]">
                      <div className="flex gap-[10px] justify-center">
                        <img
                          alt=""
                          className="w-[40px]"
                          src={Merches[item.product_id].images[0]}
                        />
                        <div>
                          <div className="w-[200px] truncate">{item.name}</div>
                          <div className="flex items-center gap-[20px] bg-[#F7F8FA] px-[4px] rounded-full w-max">
                            <svg
                              onClick={() =>
                                handleAddToCart(item.product_id, item, -1)
                              }
                              className="cursor-pointer"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.48787 14.9274C3.9903 14.9274 1.13672 11.8208 1.13672 7.99884C1.13672 4.17687 3.9903 1.07031 7.48787 1.07031C10.9854 1.07031 13.839 4.17687 13.839 7.99884C13.839 11.8208 10.9913 14.9274 7.48787 14.9274ZM7.48787 2.03708C4.47476 2.03708 2.02293 4.71182 2.02293 7.99884C2.02293 11.2859 4.47476 13.9606 7.48787 13.9606C10.501 13.9606 12.9528 11.2859 12.9528 7.99884C12.9528 4.71182 10.501 2.03708 7.48787 2.03708Z"
                                fill="#292D32"
                              />
                              <path
                                d="M9.85118 8.4824H5.12474C4.88251 8.4824 4.68164 8.26326 4.68164 7.99901C4.68164 7.73476 4.88251 7.51562 5.12474 7.51562H9.85118C10.0934 7.51562 10.2943 7.73476 10.2943 7.99901C10.2943 8.26326 10.0993 8.4824 9.85118 8.4824Z"
                                fill="#292D32"
                              />
                            </svg>
                            <div>{item.quantity}</div>
                            <svg
                              onClick={() =>
                                handleAddToCart(item.product_id, item, 1)
                              }
                              className="cursor-pointer"
                              width="15"
                              height="16"
                              viewBox="0 0 15 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.46443 14.3507C3.96096 14.3507 1.11328 11.5031 1.11328 7.99959C1.11328 4.49612 3.96096 1.64844 7.46443 1.64844C10.9679 1.64844 13.8156 4.49612 13.8156 7.99959C13.8156 11.5031 10.9679 14.3507 7.46443 14.3507ZM7.46443 2.53464C4.45133 2.53464 1.99949 4.98648 1.99949 7.99959C1.99949 11.0127 4.45133 13.4645 7.46443 13.4645C10.4775 13.4645 12.9294 11.0127 12.9294 7.99959C12.9294 4.98648 10.4775 2.53464 7.46443 2.53464Z"
                                fill="#292D32"
                              />
                              <path
                                d="M9.82774 8.44285H5.10131C4.85908 8.44285 4.6582 8.24197 4.6582 7.99974C4.6582 7.75751 4.85908 7.55664 5.10131 7.55664H9.82774C10.07 7.55664 10.2708 7.75751 10.2708 7.99974C10.2708 8.24197 10.07 8.44285 9.82774 8.44285Z"
                                fill="#292D32"
                              />
                              <path
                                d="M7.46459 10.806C7.22236 10.806 7.02148 10.6051 7.02148 10.3629V5.63646C7.02148 5.39423 7.22236 5.19336 7.46459 5.19336C7.70682 5.19336 7.90769 5.39423 7.90769 5.63646V10.3629C7.90769 10.6051 7.70682 10.806 7.46459 10.806Z"
                                fill="#292D32"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div className="sm:hidden block">
                        ${(item.retail_price * item.quantity).toFixed(2)}
                      </div>
                      <div className="max-w-[200px] flex flex-col sm:hidden block">
                        <div
                          onClick={() =>
                            handleAddToCart(item.product_id, item, -10000)
                          }
                          className="text-[#a5a5a5] text-[14px] cursor-pointer"
                        >
                          Remove
                        </div>
                      </div>
                    </td>
                  </tr>
                </>
              );
            else return <></>;
          })}
        </div>
        <div className="sm:w-max space-y-[16px]">
          <div className="space-y-[16px] border-[#EAECF0] sm:w-[400px] w-[96vw] sm:border-[1px] rounded-[8px] bg-white p-[16px]">
            <div className="text-left">Purchase Summary</div>
            <div
              onClick={() => {
                dispatch(setSidebarMode(false));
                dispatch(setSidebarShow(true));
              }}
              className="text-center px-[14px] py-[10px] rounded-[8px] bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]"
            >
              Continue to Checkout
            </div>

            <hr />
            <div className="items-center flex justify-between text-[14px]">
              <div className="flex items-center gap-[10px]">
                <div>Subtotal</div>
                <div className="text-[#a5a5a5]">({totalCounts} items)</div>
              </div>
              <div>${subTotal}</div>
            </div>
            <hr />
            <div className="items-center flex justify-between text-[14px]">
              <div className="flex items-center gap-[10px]">
                <div>Shipping fees</div>
                <div className="text-[#a5a5a5]">(estimates)</div>
              </div>
              <div>${shipping}</div>
            </div>
            <hr />
            <div className="items-center flex justify-between text-[14px]">
              <div>Taxes</div>
              <div>${tax ? tax : 0}</div>
            </div>
            <hr />
            <div className="items-center flex justify-between text-[14px]">
              <div>Estimated Total</div>
              <div>${estimateTotal}</div>
            </div>
          </div>
          {orderId && (
            <CopyToClipboard
              text={orderId}
              onCopy={() => toast.success("Copied your order ID")}
            >
              <div className="text-center px-[14px] py-[10px] rounded-[8px] bg-gradient-to-tr from-[#1C1460] to-[#2E31B7] text-white cursor-pointer sm:z-[200] transition ease-in-out duration-500 hover:shadow-[-1px_6px_10px_0_rgba(91,29,238,0.5)]">
                Order ID : {orderId}
              </div>
            </CopyToClipboard>
          )}
        </div>

        {Object.values(cartItems).map((item) => {
          if (item)
            return (
              <div className="sm:hidden mt-[10px] gap-[10px] flex items-center border-[#E9E9E9] border-[1px] rounded-[8px] p-[10px] w-[94vw]">
                <img
                  alt=""
                  className="w-[40px]"
                  src={Merches[item.product_id].images[0]}
                />
                <div className="w-full space-y-[4px]">
                  <div className="flex items-center w-full justify-between">
                    <div className="w-[180px] truncate">{item.name}</div>
                    <div>${(item.retail_price * item.quantity).toFixed(2)}</div>
                  </div>
                  <div className="flex items-center w-full justify-between">
                    <div
                      onClick={() =>
                        handleAddToCart(item.product_id, item, -10000)
                      }
                      className="text-[#a5a5a5] text-[14px] cursor-pointer"
                    >
                      Remove
                    </div>
                    <div className="flex items-center gap-[10px] bg-[#F7F8FA] px-[4px] rounded-full w-max">
                      <svg
                        onClick={() =>
                          handleAddToCart(item.product_id, item, -1)
                        }
                        className="cursor-pointer"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.48787 14.9274C3.9903 14.9274 1.13672 11.8208 1.13672 7.99884C1.13672 4.17687 3.9903 1.07031 7.48787 1.07031C10.9854 1.07031 13.839 4.17687 13.839 7.99884C13.839 11.8208 10.9913 14.9274 7.48787 14.9274ZM7.48787 2.03708C4.47476 2.03708 2.02293 4.71182 2.02293 7.99884C2.02293 11.2859 4.47476 13.9606 7.48787 13.9606C10.501 13.9606 12.9528 11.2859 12.9528 7.99884C12.9528 4.71182 10.501 2.03708 7.48787 2.03708Z"
                          fill="#292D32"
                        />
                        <path
                          d="M9.85118 8.4824H5.12474C4.88251 8.4824 4.68164 8.26326 4.68164 7.99901C4.68164 7.73476 4.88251 7.51562 5.12474 7.51562H9.85118C10.0934 7.51562 10.2943 7.73476 10.2943 7.99901C10.2943 8.26326 10.0993 8.4824 9.85118 8.4824Z"
                          fill="#292D32"
                        />
                      </svg>
                      <div>{item.quantity}</div>
                      <svg
                        onClick={() =>
                          handleAddToCart(item.product_id, item, 1)
                        }
                        className="cursor-pointer"
                        width="15"
                        height="16"
                        viewBox="0 0 15 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.46443 14.3507C3.96096 14.3507 1.11328 11.5031 1.11328 7.99959C1.11328 4.49612 3.96096 1.64844 7.46443 1.64844C10.9679 1.64844 13.8156 4.49612 13.8156 7.99959C13.8156 11.5031 10.9679 14.3507 7.46443 14.3507ZM7.46443 2.53464C4.45133 2.53464 1.99949 4.98648 1.99949 7.99959C1.99949 11.0127 4.45133 13.4645 7.46443 13.4645C10.4775 13.4645 12.9294 11.0127 12.9294 7.99959C12.9294 4.98648 10.4775 2.53464 7.46443 2.53464Z"
                          fill="#292D32"
                        />
                        <path
                          d="M9.82774 8.44285H5.10131C4.85908 8.44285 4.6582 8.24197 4.6582 7.99974C4.6582 7.75751 4.85908 7.55664 5.10131 7.55664H9.82774C10.07 7.55664 10.2708 7.75751 10.2708 7.99974C10.2708 8.24197 10.07 8.44285 9.82774 8.44285Z"
                          fill="#292D32"
                        />
                        <path
                          d="M7.46459 10.806C7.22236 10.806 7.02148 10.6051 7.02148 10.3629V5.63646C7.02148 5.39423 7.22236 5.19336 7.46459 5.19336C7.70682 5.19336 7.90769 5.39423 7.90769 5.63646V10.3629C7.90769 10.6051 7.70682 10.806 7.46459 10.806Z"
                          fill="#292D32"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            );
          else return <></>;
        })}
      </div>
    </>
  );
};
