import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export const ImageLoader = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);

  const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const handleImageLoad = async () => {
    // await delay(2000);
    setIsLoading(false);
  };

  return (
    <div>
      {/* {isLoading && <Skeleton height={300} baseColor="#ffffff" />} */}

      <img
        alt=""
        className="w-full mx-auto rounded-[12px]"
        src={src}
        onLoad={handleImageLoad}
      />
    </div>
  );
};
