import chainflip_oversized_tshirt1 from "./Chainflip/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-front-666e212b9c5ee.png";
import chainflip_oversized_tshirt2 from "./Chainflip/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-front-666e212b9d79a.png";
import chainflip_oversized_tshirt3 from "./Chainflip/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-left-front-666e212b9dca9.png";
import maya_oversized_tshirt1 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-back-666e1af3c1c34.png";
import maya_oversized_tshirt2 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-back-666e1af3c2093.png";
import maya_oversized_tshirt3 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-front-666e1af3c0e25.png";
import maya_oversized_tshirt4 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-front-666e1af3c17ff.png";
import maya_oversized_tshirt5 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-left-front-666e1c2083179.png";
import maya_oversized_tshirt6 from "./Maya Protocol/Oversized t-shirt/oversized-faded-t-shirt-faded-bone-right-666e1c20837d7.png";
import thor_oversized_tshirt1 from "./Thorchain/Oversized T-shirt/oversized-faded-t-shirt-faded-bone-back-666e28b45d464.png";
import thor_oversized_tshirt2 from "./Thorchain/Oversized T-shirt/oversized-faded-t-shirt-faded-bone-back-666e28b45d8b2.png";
import thor_oversized_tshirt3 from "./Thorchain/Oversized T-shirt/oversized-faded-t-shirt-faded-bone-front-666e28b45bfc4.png";
import thor_oversized_tshirt4 from "./Thorchain/Oversized T-shirt/oversized-faded-t-shirt-faded-bone-front-666e28b45cf85.png";
import maya_chocolate_tshirt1 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-back-666e19d903cd1.png";
import maya_chocolate_tshirt2 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-back-666e19d903e50.png";
import maya_chocolate_tshirt3 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-front-666e19d90367d.png";
import maya_chocolate_tshirt4 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-front-666e19d903bc5.png";
import maya_chocolate_tshirt5 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-left-666e19d903f8a.png";
import maya_chocolate_tshirt6 from "./Maya Protocol/Crew neck t-shirt/all-over-print-mens-crew-neck-t-shirt-white-left-666e19d90406c.png";
import chainflip_fitted_tshirt1 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-heather-grey-front-666e211f0692f.png";
import chainflip_fitted_tshirt2 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-heather-grey-front-666e211f07bc6.png";
import chainflip_fitted_tshirt3 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-heather-grey-left-front-666e211f07585.png";
import chainflip_fitted_tshirt4 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-heather-grey-right-front-666e211f078c3.png";
import chainflip_fitted_tshirt5 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-white-front-666e211f08b68.png";
import chainflip_fitted_tshirt6 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-white-front-666e211f090e7.png";
import chainflip_fitted_tshirt7 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-white-left-front-666e211f07ee7.png";
import chainflip_fitted_tshirt8 from "./Chainflip/fitted t-shirt/mens-fitted-t-shirt-white-right-front-666e211f0858b.png";
import cut_sticker_sheet1 from "./Stickers/kiss-cut-sticker-sheet-white-front-666dfa2359c5e.png";
import cut_sticker_sheet2 from "./Stickers/kiss-cut-sticker-sheet-white-front-666e21370f49e.png";
import bucket_hat1 from "./Chainflip/Reversible Bucket hat/all-over-print-reversible-bucket-hat-white-back-outside-666e1cb487e4d.png";
import bucket_hat2 from "./Chainflip/Reversible Bucket hat/all-over-print-reversible-bucket-hat-white-front-outside-666e1cb487b85.png";
import bucket_hat3 from "./Chainflip/Reversible Bucket hat/all-over-print-reversible-bucket-hat-white-right-front-inside-666e1cb48810f.png";
import bucket_hat4 from "./Maya Protocol/reversible Bucket hat/all-over-print-reversible-bucket-hat-white-right-front-outside-666dfa7720722.png";
import bucket_hat5 from "./Maya Protocol/reversible Bucket hat/all-over-print-reversible-bucket-hat-white-right-front-outside-666dfa7720a77.png";
import bucket_hat6 from "./Thorchain/Reversible bucket hat/all-over-print-reversible-bucket-hat-white-back-outside-666e218253405.png";
import bucket_hat7 from "./Thorchain/Reversible bucket hat/all-over-print-reversible-bucket-hat-white-left-front-outside-666e218253057.png";
import bucket_hat8 from "./Thorchain/Reversible bucket hat/all-over-print-reversible-bucket-hat-white-right-front-outside-666e218252cc2.png";
import bucket_hat9 from "./Thorchain/Reversible bucket hat/all-over-print-reversible-bucket-hat-white-right-outside-666e218253279.png";
import classic_dad_hat_chainflip1 from "./Chainflip/Classic Dad hat/classic-dad-hat-pink-front-666e1d88c80a3.png";
import classic_dad_hat_chainflip2 from "./Chainflip/Classic Dad hat/classic-dad-hat-pink-left-666e1d88c943d.png";
import classic_dad_hat_chainflip3 from "./Chainflip/Classic Dad hat/classic-dad-hat-pink-right-front-666e1d88c902d.png";
import classic_dad_hat_chainflip4 from "./Chainflip/Classic Dad hat/classic-dad-hat-white-front-666e1d88c9ec3.png";
import classic_dad_hat_chainflip5 from "./Chainflip/Classic Dad hat/classic-dad-hat-white-left-666e1d88ca472.png";
import classic_dad_hat_chainflip6 from "./Chainflip/Classic Dad hat/classic-dad-hat-white-right-front-666e1d88c98e5.png";
import classic_dad_hat_maya1 from "./Maya Protocol/Dad hat/classic-dad-hat-light-blue-front-666e0578e3273.png";
import classic_dad_hat_maya2 from "./Maya Protocol/Dad hat/classic-dad-hat-light-blue-front-666e0578e3d19.png";
import classic_dad_hat_maya3 from "./Maya Protocol/Dad hat/classic-dad-hat-light-blue-front-666e0578e416f.png";
import classic_dad_hat_maya4 from "./Maya Protocol/Dad hat/classic-dad-hat-white-front-666e0578e44e8.png";
import classic_dad_hat_maya5 from "./Maya Protocol/Dad hat/classic-dad-hat-white-front-666e0578e4a5d.png";
import classic_dad_hat_maya6 from "./Maya Protocol/Dad hat/classic-dad-hat-white-front-666e0578e4f39.png";
import classic_dad_hat_thor1 from "./Thorchain/Classic dad hat/classic-dad-hat-dark-grey-front-666e281ae7950.png";
import classic_dad_hat_thor2 from "./Thorchain/Classic dad hat/classic-dad-hat-dark-grey-front-666e281ae7c9c.png";
import classic_dad_hat_thor3 from "./Thorchain/Classic dad hat/classic-dad-hat-dark-grey-left-666e281ae8016.png";
import classic_dad_hat_thor4 from "./Thorchain/Classic dad hat/classic-dad-hat-dark-grey-right-666e281ae840e.png";
import classic_dad_hat_thor5 from "./Thorchain/Classic dad hat/classic-dad-hat-dark-grey-right-front-666e281ae7574.png";
import classic_dad_hat_thor6 from "./Thorchain/Classic dad hat/classic-dad-hat-spruce-front-666e281ae6067.png";
import classic_dad_hat_thor7 from "./Thorchain/Classic dad hat/classic-dad-hat-spruce-front-666e281ae6b5d.png";
import classic_dad_hat_thor8 from "./Thorchain/Classic dad hat/classic-dad-hat-spruce-left-666e281ae6e0a.png";
import classic_dad_hat_thor9 from "./Thorchain/Classic dad hat/classic-dad-hat-spruce-right-666e281ae71b7.png";
import classic_dad_hat_thor10 from "./Thorchain/Classic dad hat/classic-dad-hat-spruce-right-front-666e281ae68b2.png";
import stainless_steel_bottle1 from "./Stainless steal bottles/stainless-steel-water-bottle-black-17-oz-front-2-666e28257e34a.png";
import stainless_steel_bottle2 from "./Stainless steal bottles/stainless-steel-water-bottle-white-17-oz-front-2-666e1e28492b9.png";
import stainless_steel_bottle3 from "./Stainless steal bottles/stainless-steel-water-bottle-white-17-oz-front-666e05db62700.png";
import enamel_mug_chainflip1 from "./Chainflip/Enamel mug/enamel-mug-white-12-oz-front-666e1f4667fbc.png";
import enamel_mug_chainflip2 from "./Chainflip/Enamel mug/enamel-mug-white-12-oz-left-666e1f466820c.png";
import enamel_mug_maya1 from "./Maya Protocol/enamel mug/enamel-mug-white-12-oz-front-666e06a92a015.png";
import enamel_mug_maya2 from "./Maya Protocol/enamel mug/enamel-mug-white-12-oz-front-666e06a92a0bb.png";
import enamel_mug_thor1 from "./Thorchain/Enamel Mug/enamel-mug-white-12-oz-left-666e28360b448.png";
import enamel_mug_thor2 from "./Thorchain/Enamel Mug/enamel-mug-white-12-oz-right-666e28360b341.png";
import tote_bag_chainflip1 from "./Chainflip/Tote Bag/all-over-print-tote-bag-black-15x15-front-666e200ae1794.png";
import tote_bag_chainflip2 from "./Chainflip/Tote Bag/all-over-print-tote-bag-black-15x15-mockup-666e200ae13d8.png";
import tote_bag_chainflip3 from "./Chainflip/Tote Bag/all-over-print-tote-bag-black-15x15-product-details-666e2016815bf.png";
import tote_bag_maya1 from "./Maya Protocol/Tote bag/all-over-print-tote-bag-black-15x15-front-666e07639c4c0.png";
import tote_bag_maya2 from "./Maya Protocol/Tote bag/all-over-print-tote-bag-black-15x15-mockup-666e07639c399.png";
import tote_bag_thor1 from "./Thorchain/Tote bag/all-over-print-tote-bag-black-15x15-front-666e28428ba6b.png";
import tote_bag_thor2 from "./Thorchain/Tote bag/all-over-print-tote-bag-black-15x15-mockup-666e28428bae6.png";
import tote_bag_thor3 from "./Thorchain/Tote bag/all-over-print-tote-bag-black-15x15-product-details-666e28428b96d.png";
import thor_jersey1 from "./Thorchain/Hockey Jersey/all-over-print-recycled-hockey-fan-jersey-white-back-666e284fda84e.png";
import thor_jersey2 from "./Thorchain/Hockey Jersey/all-over-print-recycled-hockey-fan-jersey-white-back-666e284fda94c.png";
import thor_jersey3 from "./Thorchain/Hockey Jersey/all-over-print-recycled-hockey-fan-jersey-white-front-666e284fda3db.png";
import thor_jersey4 from "./Thorchain/Hockey Jersey/all-over-print-recycled-hockey-fan-jersey-white-front-666e284fda738.png";
import thor_jersey5 from "./Thorchain/Hockey Jersey/all-over-print-recycled-hockey-fan-jersey-white-product-details-666e284fdaa65.png";
import maya_hoodie1 from "./Maya Protocol/Unisexe hoodie/unisex-eco-raglan-hoodie-black-front-666e188f50e3d.png";
import maya_hoodie2 from "./Maya Protocol/Unisexe hoodie/unisex-eco-raglan-hoodie-black-front-666e188f516da.png";
import maya_hoodie3 from "./Maya Protocol/Unisexe hoodie/unisex-eco-raglan-hoodie-white-front-666e188f519ec.png";
import maya_hoodie4 from "./Maya Protocol/Unisexe hoodie/unisex-eco-raglan-hoodie-white-front-666e188f51f8d.png";
import chainflip_hoodie1 from "./Chainflip/Unisexe Hoodie/all-over-print-recycled-unisex-hoodie-white-back-666e210a04d0e.png";
import chainflip_hoodie2 from "./Chainflip/Unisexe Hoodie/all-over-print-recycled-unisex-hoodie-white-back-666e210a04e8e.png";
import chainflip_hoodie3 from "./Chainflip/Unisexe Hoodie/all-over-print-recycled-unisex-hoodie-white-front-666e210a048b3.png";
import chainflip_hoodie4 from "./Chainflip/Unisexe Hoodie/all-over-print-recycled-unisex-hoodie-white-front-666e210a04b18.png";
import chainflip_shirt1 from "./Chainflip/long sleeve shirts/mens-fitted-long-sleeve-shirt-white-front-666e20efdc20a.png";
import chainflip_shirt2 from "./Chainflip/long sleeve shirts/mens-fitted-long-sleeve-shirt-white-front-666e20efdcd5b.png";
import chainflip_shirt3 from "./Chainflip/long sleeve shirts/mens-fitted-long-sleeve-shirt-white-front-666e20efdcefa.png";
import thor_hoodie1 from "./Thorchain/Unisexe hoodie/all-over-print-recycled-unisex-hoodie-white-back-666e285ea58b1.png";
import thor_hoodie2 from "./Thorchain/Unisexe hoodie/all-over-print-recycled-unisex-hoodie-white-back-666e285ea5a5c.png";
import thor_hoodie3 from "./Thorchain/Unisexe hoodie/all-over-print-recycled-unisex-hoodie-white-front-666e285ea514a.png";
import thor_hoodie4 from "./Thorchain/Unisexe hoodie/all-over-print-recycled-unisex-hoodie-white-front-666e285ea5632.png";
import thor_hodl from "./Thorchain/HODL/HODL.png";
import accelerate_tshirt1 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-solid-white-triblend-back-666e289c2e210.png";
import accelerate_tshirt2 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-solid-white-triblend-front-666e289c29851.png";
import accelerate_tshirt3 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-solid-white-triblend-front-666e289c2cf79.png";
import accelerate_tshirt4 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-solid-white-triblend-left-front-666e289c2aa38.png";
import accelerate_tshirt5 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-solid-white-triblend-right-front-666e289c2bd05.png";
import accelerate_tshirt6 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-white-fleck-triblend-back-666e289c2934d.png";
import accelerate_tshirt7 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-white-fleck-triblend-front-666e289c27a5b.png";
import accelerate_tshirt8 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-white-fleck-triblend-front-666e289c28e14.png";
import accelerate_tshirt9 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-white-fleck-triblend-left-front-666e289c28333.png";
import accelerate_tshirt10 from "./Thorchain/Unisexe T-shirt/unisex-tri-blend-t-shirt-white-fleck-triblend-right-front-666e289c28920.png";
import maya_shirt1 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-black-back-666e083d481ab.png";
import maya_shirt2 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-black-front-666e083d4775d.png";
import maya_shirt3 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-dark-grey-heather-back-666e083d4a8e2.png";
import maya_shirt4 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-dark-grey-heather-front-666e083d498c9.png";
import maya_shirt5 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-navy-back-666e083d48f2f.png";
import maya_shirt6 from "./Maya Protocol/Unisexe long sleeve tee/unisex-long-sleeve-tee-navy-front-666e083d4854e.png";

export const Products = {
  "Oversized T-Shirt #1": {
    images: {
      Chainflip: [
        chainflip_oversized_tshirt1,
        chainflip_oversized_tshirt2,
        chainflip_oversized_tshirt3,
      ],
      Maya: [
        maya_oversized_tshirt1,
        maya_oversized_tshirt2,
        maya_oversized_tshirt3,
        maya_oversized_tshirt4,
        maya_oversized_tshirt5,
        maya_oversized_tshirt6,
      ],
      Thorchain: [
        thor_oversized_tshirt1,
        thor_oversized_tshirt2,
        thor_oversized_tshirt3,
        thor_oversized_tshirt4,
      ],
    },
    "Style and fit": {
      "Oversized fit":
        "The item has a trendy, oversized fit that works well for outfit layering.",
      "Streetwear look":
        "The t-shirt's heavyweight fabric gives it a structured look that's perfect for streetwear outfits.",
    },
    material: {
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra rough",
    },
    features: {
      Durable: "A long-lasting item that won't wear out quickly.",
    },
  },
  "Chocolate Day T-Shirt #2": {
    images: [
      maya_chocolate_tshirt1,
      maya_chocolate_tshirt2,
      maya_chocolate_tshirt3,
      maya_chocolate_tshirt4,
      maya_chocolate_tshirt5,
      maya_chocolate_tshirt6,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Casual wear": "A classic, everyday option loved by our customers.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
  },
  "Chainflip Fitted T-Shirt #2": {
    images: [
      chainflip_fitted_tshirt1,
      chainflip_fitted_tshirt2,
      chainflip_fitted_tshirt3,
      chainflip_fitted_tshirt4,
      chainflip_fitted_tshirt5,
      chainflip_fitted_tshirt6,
      chainflip_fitted_tshirt7,
      chainflip_fitted_tshirt8,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Premium quality":
        "The product is made from premium, high-quality materials.",
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
    },
  },
  "Hoodie Maya Mask #3": {
    images: [maya_hoodie1, maya_hoodie2, maya_hoodie3, maya_hoodie4],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Eco-friendly":
        "The product consists of a minimum of 60% organic cotton and 20% recycled polyester.",
      "Premium quality":
        "The product is made from premium, high-quality materials.",
    },
  },
  "Chainflip Hoodie #3": {
    images: [
      chainflip_hoodie1,
      chainflip_hoodie2,
      chainflip_hoodie3,
      chainflip_hoodie4,
    ],
    "Style and fit": {
      "Relaxed fit":
        "A looser fit that provides extra comfort and free movement.",
      "Drop shoulders": "The shoulder seams are wider and end on the sleeve.",
    },
    material: {
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      "Eco-friendly":
        "The product consists of a minimum of 95% recycled polyester",
      "Premium quality":
        "The product is made from premium, high-quality materials.",
    },
  },
  "THORChain Hoodie #3": {
    images: [thor_hoodie1, thor_hoodie2, thor_hoodie3, thor_hoodie4],
    "Style and fit": {
      "Relaxed fit":
        "A looser fit that provides extra comfort and free movement.",
      "Drop shoulders": "The shoulder seams are wider and end on the sleeve.",
    },
    material: {
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      "Eco-friendly":
        "The product consists of a minimum of 95% recycled polyester",
      "Premium quality":
        "The product is made from premium, high-quality materials.",
    },
  },
  "THORChain HODL Jersey #4": {
    images: [
      thor_jersey1,
      thor_jersey2,
      thor_jersey3,
      thor_jersey4,
      thor_jersey5,
    ],
    "Style and fit": {
      "Relaxed fit":
        "A looser fit that provides extra comfort and free movement.",
      Activewear: "Perfect for workouts and physical activity.",
    },
    material: {
      "Softness scale": "Extra soft",
      "Fabric thickness": "Lightweight",
    },
    features: {
      "Moisture-wicking":
        "The fabric absorbs moisture from the skin and dries quickly.",
      "Eco-friendly": "The product consists of 100% recycled polyester fabric.",
    },
  },
  "ACCELERATE T-Shirt #2": {
    images: [
      accelerate_tshirt1,
      accelerate_tshirt2,
      accelerate_tshirt3,
      accelerate_tshirt4,
      accelerate_tshirt5,
      accelerate_tshirt6,
      accelerate_tshirt7,
      accelerate_tshirt8,
      accelerate_tshirt9,
      accelerate_tshirt10,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Elastic: "Stretchy material that gives into movement.",
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Produced responsibly":
        "The manufacturing of this item is lawful, ethical, and safe to the workers and environment.",
    },
  },
  "Maya Protocol Shirt #4": {
    images: [
      maya_shirt1,
      maya_shirt2,
      maya_shirt3,
      maya_shirt4,
      maya_shirt5,
      maya_shirt6,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Ribbed cuffs": "A tighter fit around the cuff.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
      "Produced responsibly":
        "The manufacturing of this item is lawful, ethical, and safe to the workers and environment.",
    },
  },
  "Chainflip Fitted Shirt #4": {
    images: [chainflip_shirt1, chainflip_shirt2, chainflip_shirt3],
    "Style and fit": {
      "Slim fit":
        "A tightly fit item that creates a flattering look on the body.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
    },
  },
  "Tote Bag #5": {
    images: {
      chainflip: [
        tote_bag_chainflip1,
        tote_bag_chainflip2,
        tote_bag_chainflip3,
      ],
      maya: [tote_bag_maya1, tote_bag_maya2],
      thorchain: [tote_bag_thor1, tote_bag_thor2, tote_bag_thor3],
    },
    details: {
      Capacity: "2.6 gallons (10 l)",
      "Maximum weight limit": "44 lbs (20 kg)",
      Handles: "Cotton webbing handles",
    },
    material: {
      Composition: "100% polyester",
      Description: "Durable fabric with a fusible backing.",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      Quality:
        "Premium quality: The product is made from premium, high-quality materials.",
    },
  },
  "Enamel Mug #6": {
    images: {
      chainflip: [enamel_mug_chainflip1, enamel_mug_chainflip2],
      maya: [enamel_mug_maya1, enamel_mug_maya2],
      thorchain: [enamel_mug_thor1, enamel_mug_thor2],
    },
    details: {
      Size: "12 oz",
    },
    material: {
      "Metal mug": "",
      "enamel layer": "Lightweight and durable",
    },
    features: {
      "Colored rim": "The mug has a stylish silver rim",
    },
  },
  "Classic Dad Cap #8": {
    images: {
      chainflip: [
        classic_dad_hat_chainflip1,
        classic_dad_hat_chainflip2,
        classic_dad_hat_chainflip3,
        classic_dad_hat_chainflip4,
        classic_dad_hat_chainflip5,
        classic_dad_hat_chainflip6,
      ],
      maya: [
        classic_dad_hat_maya1,
        classic_dad_hat_maya2,
        classic_dad_hat_maya3,
        classic_dad_hat_maya4,
        classic_dad_hat_maya5,
        classic_dad_hat_maya6,
      ],
      thorchain: [
        classic_dad_hat_thor1,
        classic_dad_hat_thor2,
        classic_dad_hat_thor3,
        classic_dad_hat_thor4,
        classic_dad_hat_thor5,
        classic_dad_hat_thor6,
        classic_dad_hat_thor7,
        classic_dad_hat_thor8,
        classic_dad_hat_thor9,
        classic_dad_hat_thor10,
      ],
    },
    details: {
      "Low profile": "",
      "Curved visor": "",
      "6-panel": "",
      "6 eyelets": "",
      "Buckle closure": "",
    },
    material: {
      Unstructured:
        "The crown of the hat doesn’t maintain its shape when taken off",
      "Breathability scale": "High",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers",
    },
  },
  "Bucket Hat #9": {
    images: {
      chainflip: [bucket_hat1, bucket_hat2, bucket_hat3],
      maya: [bucket_hat4, bucket_hat5],
      thorchain: [bucket_hat6, bucket_hat7, bucket_hat8, bucket_hat9],
    },
    details: {
      Reversible: "Available for printing on both sides",
      "Available sizes": ["XS", "S/M", "L/XL"],
    },
    material: {
      "100% polyester": "Fabric with a linen feel",
      "Moisture-wicking":
        "The fabric absorbs excess moisture and dries quickly",
      Breathable: "This fabric has high air permeability",
    },
    features: {
      Trendy: "Features a popular product, making it a trendy piece",
      "Premium quality":
        "The product is made from premium, high-quality materials",
      Unisex: "Fits well for both men and women",
    },
  },
  "Stainless Steel Water Bottle #7": {
    images: [
      stainless_steel_bottle1,
      stainless_steel_bottle2,
      stainless_steel_bottle3,
    ],
    details: {
      Size: "17 oz",
    },
    material: {
      "Stainless steel": "Resistant to tarnishing and rust",
    },
    features: {
      "Double-wall construction":
        "This bottle keeps the liquid temperature constant for several hours",
      "Glossy finish": "A vibrant and shiny look",
    },
  },
  "Cut Sticker Sheet #10": {
    images: [cut_sticker_sheet1, cut_sticker_sheet2],
    details: {
      Size: "5.8” x 8.3” (14.8 x 21 cm)",
    },
    material: {
      Paper: "A fibrous material",
    },
    features: {
      Glossy:
        "Has a glossy finish that adds a light shine to the final product",
    },
  },
};

export const Merches = {
  "666dd8b58f7029": {
    price: 10.5,
    images: [cut_sticker_sheet1],
    details: {
      Size: "5.8” x 8.3” (14.8 x 21 cm)",
    },
    material: {
      Paper: "A fibrous material",
    },
    features: {
      Glossy:
        "Has a glossy finish that adds a light shine to the final product",
    },
  },
  "666dd829cd1266": {
    price: 41.5,
    images: [bucket_hat4, bucket_hat5],
    details: {
      Reversible: "Available for printing on both sides",
      "Available sizes": ["XS", "S/M", "L/XL"],
    },
    material: {
      "100% polyester": "Fabric with a linen feel",
      "Moisture-wicking":
        "The fabric absorbs excess moisture and dries quickly",
      Breathable: "This fabric has high air permeability",
    },
    features: {
      Trendy: "Features a popular product, making it a trendy piece",
      "Premium quality":
        "The product is made from premium, high-quality materials",
      Unisex: "Fits well for both men and women",
    },
  },
  "666dd5bc28db89": {
    price: 35.5,
    images: [
      classic_dad_hat_maya1,
      classic_dad_hat_maya2,
      classic_dad_hat_maya3,
      classic_dad_hat_maya4,
      classic_dad_hat_maya5,
      classic_dad_hat_maya6,
    ],
    details: {
      "Low profile": "",
      "Curved visor": "",
      "6-panel": "",
      "6 eyelets": "",
      "Buckle closure": "",
    },
    material: {
      Material: "100% chino cotton twill",
      Unstructured:
        "The crown of the hat doesn’t maintain its shape when taken off",
      "Breathability scale": "High",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers",
    },
  },
  "666dd3e6dce4d6": {
    price: 36,
    images: [
      // stainless_steel_bottle1,
      // stainless_steel_bottle2,
      stainless_steel_bottle3,
    ],
    details: {
      Size: "17 oz",
    },
    material: {
      "Stainless steel": "Resistant to tarnishing and rust",
    },
    features: {
      "Double-wall construction":
        "This bottle keeps the liquid temperature constant for several hours",
      "Glossy finish": "A vibrant and shiny look",
    },
  },
  "666dd3360f2b61": {
    price: 20.5,
    images: [enamel_mug_maya1, enamel_mug_maya2],
    details: {
      Size: "12 oz",
    },
    material: {
      "Metal mug": "",
      "enamel layer": "Lightweight and durable",
    },
    features: {
      "Colored rim": "The mug has a stylish silver rim",
    },
  },
  "666dd28f6cb706": {
    price: 27.5,
    images: [tote_bag_maya1, tote_bag_maya2],
    details: {
      Capacity: "2.6 gallons (10 l)",
      "Maximum weight limit": "44 lbs (20 kg)",
      Handles: "Cotton webbing handles",
    },
    material: {
      Composition: "100% polyester",
      Description: "Durable fabric with a fusible backing.",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      Quality:
        "Premium quality: The product is made from premium, high-quality materials.",
    },
  },
  "666dd1c443bd64": {
    price: 43,
    images: [
      maya_shirt1,
      maya_shirt2,
      maya_shirt3,
      maya_shirt4,
      maya_shirt5,
      maya_shirt6,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      Material: "100% airlume combed ring-spun cotton",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Ribbed cuffs": "A tighter fit around the cuff.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
      "Produced responsibly":
        "The manufacturing of this item is lawful, ethical, and safe to the workers and environment.",
    },
  },
  "666dcfd8dbc297": {
    price: 75.5,
    images: [maya_hoodie1, maya_hoodie2, maya_hoodie3, maya_hoodie4],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
    },
    material: {
      Outside: "100% organic cotton",
      Inside: "80% organic cotton, 20% recycled polyester",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Eco-friendly":
        "The product consists of a minimum of 60% organic cotton and 20% recycled polyester.",
      "Premium quality":
        "The product is made from premium, high-quality materials.",
    },
  },
  "666dcf2b1b21f2": {
    price: 43,
    images: [
      maya_chocolate_tshirt1,
      maya_chocolate_tshirt2,
      maya_chocolate_tshirt3,
      maya_chocolate_tshirt4,
      maya_chocolate_tshirt5,
      maya_chocolate_tshirt6,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Casual wear": "A classic, everyday option loved by our customers.",
    },
    material: {
      Material:
        "95% polyester, 5% elastane (fabric composition may vary by 1%)",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
  },
  "666dcb0a7a5488": {
    price: 46,
    images: [
      maya_oversized_tshirt1,
      maya_oversized_tshirt2,
      maya_oversized_tshirt3,
      maya_oversized_tshirt4,
      maya_oversized_tshirt5,
      maya_oversized_tshirt6,
    ],
    "Style and fit": {
      "Oversized fit":
        "The item has a trendy, oversized fit that works well for outfit layering.",
      "Streetwear look":
        "The t-shirt's heavyweight fabric gives it a structured look that's perfect for streetwear outfits.",
    },
    material: {
      Material: "100% carded cotton",
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra rough",
    },
    features: {
      Durable: "A long-lasting item that won't wear out quickly.",
    },
  },
  "666dc9b96f5cd6": {
    price: 41.5,
    images: [bucket_hat1, bucket_hat2, bucket_hat3],
    details: {
      Reversible: "Available for printing on both sides",
      "Available sizes": ["XS", "S/M", "L/XL"],
    },
    material: {
      "100% polyester": "Fabric with a linen feel",
      "Moisture-wicking":
        "The fabric absorbs excess moisture and dries quickly",
      Breathable: "This fabric has high air permeability",
    },
    features: {
      Trendy: "Features a popular product, making it a trendy piece",
      "Premium quality":
        "The product is made from premium, high-quality materials",
      Unisex: "Fits well for both men and women",
    },
  },
  "666dc68fcc18f7": {
    price: 30,
    images: [
      classic_dad_hat_chainflip1,
      classic_dad_hat_chainflip2,
      classic_dad_hat_chainflip3,
      classic_dad_hat_chainflip4,
      classic_dad_hat_chainflip5,
      classic_dad_hat_chainflip6,
    ],
    details: {
      "Low profile": "",
      "Curved visor": "",
      "6-panel": "",
      "6 eyelets": "",
      "Buckle closure": "",
    },
    material: {
      Material: "100% chino cotton twill",
      Unstructured:
        "The crown of the hat doesn’t maintain its shape when taken off",
      "Breathability scale": "High",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers",
    },
  },
  "666dc60a9dda18": {
    price: 36,
    images: [
      // stainless_steel_bottle1,
      stainless_steel_bottle2,
      // stainless_steel_bottle3,
    ],
    details: {
      Size: "17 oz",
    },
    material: {
      "Stainless steel": "Resistant to tarnishing and rust",
    },
    features: {
      "Double-wall construction":
        "This bottle keeps the liquid temperature constant for several hours",
      "Glossy finish": "A vibrant and shiny look",
    },
  },
  "666dc5b8071711": {
    price: 20.5,
    images: [enamel_mug_chainflip1, enamel_mug_chainflip2],
    details: {
      Size: "12 oz",
    },
    material: {
      "Metal mug": "",
      "enamel layer": "Lightweight and durable",
    },
    features: {
      "Colored rim": "The mug has a stylish silver rim",
    },
  },
  "666dc5689b5863": {
    price: 27.5,
    images: [tote_bag_chainflip1, tote_bag_chainflip2, tote_bag_chainflip3],
    details: {
      Capacity: "2.6 gallons (10 l)",
      "Maximum weight limit": "44 lbs (20 kg)",
      Handles: "Cotton webbing handles",
    },
    material: {
      Composition: "100% polyester",
      Description: "Durable fabric with a fusible backing.",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      Quality:
        "Premium quality: The product is made from premium, high-quality materials.",
    },
  },
  "666dc2f4b4fdd9": {
    price: 34.5,
    images: [chainflip_shirt1, chainflip_shirt2, chainflip_shirt3],
    "Style and fit": {
      "Slim fit":
        "A tightly fit item that creates a flattering look on the body.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      Material: "100% combed ring-spun cotton",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
    },
  },
  "666dc2a2a8bae2": {
    price: 76,
    images: [
      chainflip_hoodie1,
      chainflip_hoodie2,
      chainflip_hoodie3,
      chainflip_hoodie4,
    ],
    "Style and fit": {
      "Relaxed fit":
        "A looser fit that provides extra comfort and free movement.",
      "Drop shoulders": "The shoulder seams are wider and end on the sleeve.",
    },
    material: {
      Material: "95% recycled polyester, 5% spandex",
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      "Eco-friendly":
        "The product consists of a minimum of 95% recycled polyester",
      "Premium quality":
        "The product is made from premium, high-quality materials.",
    },
  },
  "666dc0a6e62a08": {
    price: 33,
    images: [
      chainflip_fitted_tshirt1,
      chainflip_fitted_tshirt2,
      chainflip_fitted_tshirt3,
      chainflip_fitted_tshirt4,
      chainflip_fitted_tshirt5,
      chainflip_fitted_tshirt6,
      chainflip_fitted_tshirt7,
      chainflip_fitted_tshirt8,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      Material: "100% ring-spun combed cotton",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      "Premium quality":
        "The product is made from premium, high-quality materials.",
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Tear-away tag":
        "Easily removable tear-away tag that allows you to add a custom inside label.",
    },
  },
  "666dc0159f0db1": {
    price: 41.5,
    images: [
      chainflip_oversized_tshirt1,
      chainflip_oversized_tshirt2,
      chainflip_oversized_tshirt3,
    ],
    "Style and fit": {
      "Oversized fit":
        "The item has a trendy, oversized fit that works well for outfit layering.",
      "Streetwear look":
        "The t-shirt's heavyweight fabric gives it a structured look that's perfect for streetwear outfits.",
    },
    material: {
      Material: "100% carded cotton",
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra rough",
    },
    features: {
      Durable: "A long-lasting item that won't wear out quickly.",
    },
  },
  "666dbf429fee74": {
    price: 10.5,
    images: [cut_sticker_sheet2],
    details: {
      Size: "5.8” x 8.3” (14.8 x 21 cm)",
    },
    material: {
      Paper: "A fibrous material",
    },
    features: {
      Glossy:
        "Has a glossy finish that adds a light shine to the final product",
    },
  },
  "666dbd746b0248": {
    price: 41.5,
    images: [bucket_hat6, bucket_hat7, bucket_hat8, bucket_hat9],
    details: {
      Reversible: "Available for printing on both sides",
      "Available sizes": ["XS", "S/M", "L/XL"],
    },
    material: {
      "100% polyester": "Fabric with a linen feel",
      "Moisture-wicking":
        "The fabric absorbs excess moisture and dries quickly",
      Breathable: "This fabric has high air permeability",
    },
    features: {
      Trendy: "Features a popular product, making it a trendy piece",
      "Premium quality":
        "The product is made from premium, high-quality materials",
      Unisex: "Fits well for both men and women",
    },
  },
  "666dbace793281": {
    price: 33.5,
    images: [
      classic_dad_hat_thor1,
      classic_dad_hat_thor2,
      classic_dad_hat_thor3,
      classic_dad_hat_thor4,
      classic_dad_hat_thor5,
      classic_dad_hat_thor6,
      classic_dad_hat_thor7,
      classic_dad_hat_thor8,
      classic_dad_hat_thor9,
      classic_dad_hat_thor10,
    ],
    details: {
      "Low profile": "",
      "Curved visor": "",
      "6-panel": "",
      "6 eyelets": "",
      "Buckle closure": "",
    },
    material: {
      Material: "100% chino cotton twill",
      Unstructured:
        "The crown of the hat doesn’t maintain its shape when taken off",
      "Breathability scale": "High",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers",
    },
  },
  "666db730980306": {
    price: 36,
    images: [stainless_steel_bottle1],
    details: {
      Size: "17 oz",
    },
    material: {
      "Stainless steel": "Resistant to tarnishing and rust",
    },
    features: {
      "Double-wall construction":
        "This bottle keeps the liquid temperature constant for several hours",
      "Glossy finish": "A vibrant and shiny look",
    },
  },
  "666db630bfedc5": {
    price: 20.5,
    images: [enamel_mug_thor1, enamel_mug_thor2],
    details: {
      Size: "12 oz",
    },
    material: {
      "Metal mug": "",
      "enamel layer": "Lightweight and durable",
    },
    features: {
      "Colored rim": "The mug has a stylish silver rim",
    },
  },
  "666db2ada29312": {
    price: 27.5,
    images: [tote_bag_thor1, tote_bag_thor2, tote_bag_thor3],
    details: {
      Capacity: "2.6 gallons (10 l)",
      "Maximum weight limit": "44 lbs (20 kg)",
      Handles: "Cotton webbing handles",
    },
    material: {
      Composition: "100% polyester",
      Description: "Durable fabric with a fusible backing.",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers.",
      Quality:
        "Premium quality: The product is made from premium, high-quality materials.",
    },
  },
  "666dab7a76b309": {
    price: 62.5,
    images: [
      thor_jersey1,
      thor_jersey2,
      thor_jersey3,
      thor_jersey4,
      thor_jersey5,
    ],
    "Style and fit": {
      "Relaxed fit":
        "A looser fit that provides extra comfort and free movement.",
      Activewear: "Perfect for workouts and physical activity.",
    },
    material: {
      Material: "100% recycled polyester fabric",
      "Softness scale": "Extra soft",
      "Fabric thickness": "Lightweight",
    },
    features: {
      "Moisture-wicking":
        "The fabric absorbs moisture from the skin and dries quickly.",
      "Eco-friendly": "The product consists of 100% recycled polyester fabric.",
    },
  },
  "669c33827f8815": {
    price: 29.5,
    images: [thor_hodl],
    details: {
      "Low profile": "",
      "Curved visor": "",
      "6-panel": "",
      "6 eyelets": "",
      "Buckle closure": "",
    },
    material: {
      Material: "100% chino cotton twill",
      Unstructured:
        "The crown of the hat doesn’t maintain its shape when taken off",
      "Breathability scale": "High",
      "Softness scale": "Extra soft",
    },
    features: {
      Bestseller: "A top-selling item loved by our customers",
    },
  },
  "669c2e964970d5": {
    price: 45,
    images: [
      accelerate_tshirt1,
      accelerate_tshirt2,
      accelerate_tshirt3,
      accelerate_tshirt4,
      accelerate_tshirt5,
      accelerate_tshirt6,
      accelerate_tshirt7,
      accelerate_tshirt8,
      accelerate_tshirt9,
      accelerate_tshirt10,
    ],
    "Style and fit": {
      "Regular fit": "Standard length, the fabric easily gives into movement.",
      "Side-seamed":
        "Constructed by sewing two parts together, creating a fitted look.",
    },
    material: {
      Material: "50% polyester, 25% combed ring-spun cotton, 25% rayon",
      "Fabric thickness": "Lightweight",
      "Softness scale": "Extra soft",
    },
    features: {
      Elastic: "Stretchy material that gives into movement.",
      Comfortable: "The fabric and fit of this item are extra comfy.",
      "Produced responsibly":
        "The manufacturing of this item is lawful, ethical, and safe to the workers and environment.",
    },
  },
  "669c4e418e7b04": {
    price: 48,
    images: [
      thor_oversized_tshirt1,
      thor_oversized_tshirt2,
      thor_oversized_tshirt3,
      thor_oversized_tshirt4,
    ],
    "Style and fit": {
      "Oversized fit":
        "The item has a trendy, oversized fit that works well for outfit layering.",
      "Streetwear look":
        "The t-shirt's heavyweight fabric gives it a structured look that's perfect for streetwear outfits.",
    },
    material: {
      Material: "100% carded cotton",
      "Fabric thickness": "Heavyweight",
      "Softness scale": "Extra rough",
    },
    features: {
      Durable: "A long-lasting item that won't wear out quickly.",
    },
  },
};
